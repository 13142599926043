import type {ApiAdSettings} from 'types/ad-settings'
import {Endpoints} from './request'
import {ApiService} from './service'

export class AdService extends ApiService {
  /**
   * Получение настроек показа рекламного сервисного виджета
   * https://jira.rambler-co.ru/browse/TVPROG-252
   */
  getAdSettings(): Promise<ApiAdSettings> {
    return this.request<ApiAdSettings>(Endpoints.GET_AD_SETTINGS)
  }
}

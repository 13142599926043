/**
 * Ключ состояния
 */
export enum StateKeys {
  RUNTIME = 'RUNTIME',
  CATEGORY_STORE = 'CATEGORY_STORE',
  CHANNEL_STORE = 'CHANNEL_STORE',
  SHOW_STORE = 'SHOW_STORE',
  NEWS_STORE = 'NEWS_STORE',
  META_TAGS_STORE = 'META_TAGS_STORE',
  ROUTE_PARAMS_STORE = 'ROUTE_PARAMS_STORE',
  PREMIERE_STORE = 'PREMIERE_STORE'
}

interface FieldNameValue {
  label: string
  schema?: {
    itemProp: string
    itemType: string
    itemScope?: boolean
  }
}

const ITEM_TYPE_PERSON = 'https://schema.org/Person'

/**
 * Названия полей в информация о создателях программы/фильма/шоу...
 */
export const FIELD_NAMES_IN_CREATORS_INFO: Record<string, FieldNameValue> = {
  actor: {
    label: 'Актеры',
    schema: {
      itemProp: 'actor',
      itemScope: true,
      itemType: ITEM_TYPE_PERSON
    }
  },
  composer: {
    label: 'Композитор',
    schema: {
      itemProp: 'musicBy',
      itemScope: true,
      itemType: ITEM_TYPE_PERSON
    }
  },
  director: {
    label: 'Режиссеры',
    schema: {
      itemProp: 'director',
      itemScope: true,
      itemType: ITEM_TYPE_PERSON
    }
  },
  presenter: {
    label: 'Ведущие'
  },
  producer: {
    label: 'Продюссеры',
    schema: {
      itemProp: 'producer',
      itemScope: true,
      itemType: ITEM_TYPE_PERSON
    }
  },
  writer: {
    label: 'Сценаристы',
    schema: {
      itemProp: 'author',
      itemScope: true,
      itemType: ITEM_TYPE_PERSON
    }
  },
  country: {
    label: 'Страна',
    schema: {
      itemProp: 'countryOfOrigin',
      itemScope: true,
      itemType: 'https://schema.org/Country'
    }
  },
  production: {
    label: 'Продакшн',
    schema: {
      itemProp: 'productionCompany',
      itemScope: true,
      itemType: 'https://schema.org/Organization'
    }
  }
}

import type {ApiPremiereEvent} from 'types/premiere'
import {Endpoints} from './request'
import {ApiService} from './service'

/**
 * Параметры для получения списка премьер недели
 */
interface ListPremiereEventsParams {
  city_trans: string
  weekend?: boolean
  week?: string
  channel_id?: string
  start_from: number
  limit: number
  geo_id?: string
}

export class PremiereService extends ApiService {
  /**
   * Получение списка премьер недели
   */
  listPremiereEvents(
    query: ListPremiereEventsParams
  ): Promise<{items: ApiPremiereEvent[]; count: number}> {
    return this.request<{items: ApiPremiereEvent[]; count: number}>(
      Endpoints.LIST_PREMIERE_EVENTS,
      {query}
    )
  }
}

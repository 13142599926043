import type {ApiEpisode} from 'types/episode'
import {Endpoints} from './request'
import {ApiService} from './service'

/**
 * Параметры для получения эпизода передачи
 */
interface GetEpisodeParams {
  event_id: string
  city_trans: string
}

export class EpisodeService extends ApiService {
  /**
   * Получение эпизода передачи
   */
  getEpisode(query: GetEpisodeParams): Promise<ApiEpisode> {
    return this.request<ApiEpisode>(Endpoints.GET_EPISODE, {query})
  }
}

export enum MetaTagIds {
  MAIN = 'main',
  CITY = 'city',
  DATE = 'date',
  YESTERDAY = 'yesterday',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  WEEK = 'week',
  WEEKEND = 'weekend',
  PERSONAL = 'personal',
  CHANNEL = 'channel',
  CHANNEL_DATE = 'Channel_date',
  SHOW = 'show',
  EPISODE = 'episode',
  FILM = 'film',
  MIXED_EVENT = 'mixed_event'
}

import {makeAutoObservable} from 'mobx'
import {createContext, useContext} from 'react'
import {getRamblerIdHelper} from '@rambler-id/helper'

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

export class AuthStore {
  initialized: boolean
  isFetching: boolean
  isAuthorized: boolean
  events?: AsyncReturnType<typeof getRamblerIdHelper>
  user: {chainId: string} | null

  constructor() {
    this.initialized = false
    this.isFetching = true
    this.isAuthorized = false
    this.user = null

    makeAutoObservable(this)
  }

  public async initialize(): Promise<void> {
    if (this.initialized) {
      return
    }

    this.initialized = true

    try {
      this.events = await getRamblerIdHelper()

      if (!this.events) {
        return
      }

      const {Events} = this.events

      this.events.addListener(Events.LOGIN, this.fetchUser)
      this.events.addListener(Events.OAUTHLOGIN, this.fetchUser)
      this.events.addListener(Events.LOGOUT, this.fetchUser)

      window.addEventListener('beforeunload', () => {
        this.events?.removeListener(Events.LOGIN, this.fetchUser)
        this.events?.removeListener(Events.OAUTHLOGIN, this.fetchUser)
        this.events?.removeListener(Events.LOGOUT, this.fetchUser)
      })

      this.fetchUser()
    } catch {}
  }

  private fetchUser = (): void => {
    this.events?.getProfileInfo({get_chain_id: 1}, (profile) => {
      if (profile) {
        this.isAuthorized = true
        this.user = {
          chainId: profile.info.chain_id.default
        }
      } else {
        this.isAuthorized = false
        this.user = null
      }

      this.isFetching = false
    })
  }

  public openAuth = (): void => {
    this.events?.openAuth({rname: 'tv'})
  }
}

const authStore = new AuthStore()
const authStoreContext = createContext({authStore})

export const useAuthStore = (): {authStore: AuthStore} =>
  useContext(authStoreContext)

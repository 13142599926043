import {makeAutoObservable, observable, type IObservableArray} from 'mobx'
import {format, max} from 'date-fns'

import type {Stores} from 'stores'
import type {ApiEventInList, Show} from 'types/show'
import type {Channel} from 'types/channel'
import {Endpoints} from 'common/api/request'
import {ChannelService, EventService, BulkService} from 'common/api'

const CHANNELS_SINGLE_FETCH_COUNT = 12
const SHOWS_SINGLE_FETCH_COUNT = 6

export class FavoriteStore {
  fetchingTarget: null | 'shows' | 'channels'

  readonly stores: Stores
  readonly channelService: ChannelService
  readonly eventService: EventService
  readonly bulkService: BulkService

  // TODO(TVPROG-000): зарефакторить удаление (с отменой) передач и каналов из избранного ...
  private recentlyRemovedFavoriteShows: IObservableArray<Show>
  private recentlyRemovedFavoriteChannels: IObservableArray<Channel>

  private favoriteChannelIdSet: Set<number>
  private favoriteShowIdSet: Set<number>
  private likedShowIdSet: Set<number>
  private dislikedShowIdSet: Set<number>

  readonly showsForFavoriteTitles: IObservableArray<Show>
  private favoriteTitlesMap: {
    totalCount: number
    currentCount: number
    shows: Show[]
  }

  readonly showsForFavoriteChannels: IObservableArray<Show>
  private favoriteChannelsMap: {
    totalCount: number
    currentCount: number
    channels: Channel[]
  }

  constructor(stores: Stores) {
    this.fetchingTarget = null
    this.stores = stores
    this.channelService = new ChannelService(stores.apiParams)
    this.eventService = new EventService(stores.apiParams)
    this.bulkService = new BulkService(stores.apiParams)

    this.recentlyRemovedFavoriteShows = observable([])
    this.recentlyRemovedFavoriteChannels = observable([])

    this.favoriteChannelIdSet = observable.set()
    this.favoriteShowIdSet = observable.set()
    this.likedShowIdSet = observable.set()
    this.dislikedShowIdSet = observable.set()

    this.showsForFavoriteTitles = observable.array()
    this.favoriteTitlesMap = observable.object({
      totalCount: 0,
      currentCount: 0,
      shows: []
    })

    this.showsForFavoriteChannels = observable.array()
    this.favoriteChannelsMap = observable.object({
      totalCount: 0,
      currentCount: 0,
      channels: []
    })

    makeAutoObservable(this, {stores: false})
  }

  public fetchFavoriteChannelIds = async (): Promise<void> => {
    const {items = []} = await this.channelService.listFavoriteChannelIds()

    this.favoriteChannelIdSet = new Set(items)
  }

  public fetchFavoriteShowIds = async (): Promise<void> => {
    const {
      favorited = [],
      liked = [],
      disliked = []
    } = await this.eventService.listInteractedTitleIds()

    this.favoriteShowIdSet = new Set(favorited)
    this.likedShowIdSet = new Set(liked)
    this.dislikedShowIdSet = new Set(disliked)
  }

  public clearFavoriteIds = (): void => {
    this.favoriteShowIdSet.clear()
    this.favoriteChannelIdSet.clear()
  }

  public addLikedShowId = (id: number): void => {
    this.likedShowIdSet.add(id)
  }

  public deleteLikedShowId = (id: number): void => {
    this.likedShowIdSet.delete(id)
  }

  public addDislikedShowId = (id: number): void => {
    this.dislikedShowIdSet.add(id)
  }

  public deleteDislikedShowId = (id: number): void => {
    this.dislikedShowIdSet.delete(id)
  }

  public handleChannelFavoriteChange = async (
    baseChannelId: number
  ): Promise<void> => {
    if (this.favoriteChannelIdSet.has(baseChannelId)) {
      await this.channelService.removeFavoriteChannel({
        channel_id: baseChannelId
      })

      this.favoriteChannelIdSet.delete(baseChannelId)

      const removedChannel = this.favoriteChannelsMap.channels.find(
        ({baseChannelId: id}) => id === baseChannelId
      ) as Channel

      if (removedChannel) {
        this.recentlyRemovedFavoriteChannels.push(removedChannel)
      }

      this.favoriteChannelsMap = {
        ...this.favoriteChannelsMap,
        channels: this.favoriteChannelsMap.channels.filter(
          ({baseChannelId: id}) => id !== baseChannelId
        ),
        totalCount: this.favoriteChannelsMap.totalCount - 1
      }
    } else {
      await this.channelService.favoriteChannel({
        channel_id: baseChannelId
      })

      this.favoriteChannelIdSet.add(baseChannelId)

      const channels = this.favoriteChannelsMap.channels

      const recentlyRemovedChannel = this.recentlyRemovedFavoriteChannels.find(
        ({baseChannelId: id}) => id === baseChannelId
      )

      if (recentlyRemovedChannel) {
        channels.unshift(recentlyRemovedChannel)
      }

      this.favoriteChannelsMap = {
        ...this.favoriteChannelsMap,
        totalCount: this.favoriteChannelsMap.totalCount + 1,
        channels
      }
    }
  }

  public handleShowFavoriteChange = async (titleId: number): Promise<void> => {
    if (this.favoriteShowIdSet.has(titleId)) {
      await this.eventService.removeFavoriteTitle({
        title_id: titleId
      })

      this.favoriteShowIdSet.delete(titleId)

      const removedShow = this.favoriteTitlesMap.shows.find(
        ({titleId: id}) => id === titleId
      ) as Show

      if (removedShow) {
        this.recentlyRemovedFavoriteShows.push(removedShow)
      }

      // NOTE: уменьшение totalCount'а нам надо, чтобы
      // корректно показывать кнопку с загрузить еще
      this.favoriteTitlesMap = {
        ...this.favoriteTitlesMap,
        shows: this.favoriteTitlesMap.shows.filter(
          ({titleId: id}) => id !== titleId
        ),
        totalCount: this.favoriteTitlesMap.totalCount - 1
      }
    } else {
      await this.eventService.favoriteTitle({
        title_id: titleId
      })

      this.favoriteShowIdSet.add(titleId)

      const shows = this.favoriteTitlesMap.shows

      const recentlyRemovedShow = this.recentlyRemovedFavoriteShows.find(
        ({titleId: id}) => id === titleId
      )

      if (recentlyRemovedShow) {
        shows.unshift(recentlyRemovedShow)
      }

      // NOTE: увеличение totalCount'а нам надо, чтобы
      // корректно показывать кнопку с загрузить еще
      this.favoriteTitlesMap = {
        ...this.favoriteTitlesMap,
        totalCount: this.favoriteTitlesMap.totalCount + 1,
        shows
      }
    }
  }

  public fetchFavoriteShows = async ({
    isInitialFetch
  }: {
    isInitialFetch: boolean
  }): Promise<void> => {
    if (this.fetchingTarget === 'shows') return

    this.fetchingTarget = 'shows'

    const {city} = this.stores.routeParamsStore

    const {items: fetchedShows, count} =
      await this.eventService.listFavoriteTitles({
        start_from: isInitialFetch ? 0 : this.favoriteShowsCurrentCount,
        limit:
          (isInitialFetch ? 0 : this.favoriteShowsCurrentCount) +
          SHOWS_SINGLE_FETCH_COUNT,
        city_trans: city.nameTranslit
      })

    if (!fetchedShows.length) {
      this.fetchingTarget = null

      return
    }

    const {answers: showResponses} = await this.bulkService.bulkGet<{
      count: number
      items: ApiEventInList[]
    }>({
      requests: fetchedShows.map(({channel_id, title_id, start}) => ({
        method: Endpoints.LIST_EVENTS.replace('/', ''),
        params: {
          title_id: String(title_id),
          channel_id: String(channel_id),
          time_from: max([new Date(), new Date(start)]).toISOString(),
          start_from: 0,
          limit: 3,
          city_trans: city.nameTranslit
        }
      }))
    })

    const fetchedShowsForFavoriteTitles = showResponses.reduce<
      ApiEventInList[]
    >((acc, {items}) => acc.concat(items), [])

    this.showsForFavoriteTitles.replace([
      ...(isInitialFetch ? [] : this.showsForFavoriteTitles),
      ...fetchedShowsForFavoriteTitles.map(
        this.stores.showStore.formatShowForSchedule
      )
    ])

    this.favoriteTitlesMap = {
      totalCount: count,
      currentCount:
        (isInitialFetch ? 0 : this.favoriteShowsCurrentCount) +
        fetchedShows.length,
      shows: [
        ...(isInitialFetch ? [] : this.favoriteShows),
        ...fetchedShows.map(this.stores.showStore.formatShowForSchedule)
      ]
    }

    this.fetchingTarget = null
  }

  public get favoriteShows(): Show[] {
    return this.favoriteTitlesMap.shows
  }

  public get favoriteShowsCurrentCount(): number {
    return this.favoriteTitlesMap.currentCount
  }

  public get favoriteShowsTotalCount(): number {
    return this.favoriteTitlesMap.totalCount
  }

  public fetchFavoriteChannels = async (): Promise<void> => {
    if (this.fetchingTarget === 'channels') return

    this.fetchingTarget = 'channels'

    const {date, city} = this.stores.routeParamsStore

    const {items: fetchedChannels, count} =
      await this.channelService.listFavoriteChannels({
        start_from: 0,
        limit: this.favoriteChannelsCurrentCount + CHANNELS_SINGLE_FETCH_COUNT
      })

    if (!fetchedChannels.length) {
      this.fetchingTarget = null

      return
    }

    const {items: fetchedShows} = await this.eventService.listEvents({
      channel_id: fetchedChannels.map(({channel_id: id}) => id).join(','),
      date: format(date, 'yyyy-MM-dd'),
      start_from: 0,
      limit: 1000,
      city_trans: city.nameTranslit
    })

    this.showsForFavoriteChannels.replace([
      ...this.showsForFavoriteChannels,
      ...fetchedShows.map(this.stores.showStore.formatShowForSchedule)
    ])

    this.favoriteChannelsMap = {
      channels: fetchedChannels.map(this.stores.channelStore.formatChannel),
      totalCount: count,
      currentCount: this.favoriteChannelsCurrentCount + fetchedChannels.length
    }

    this.fetchingTarget = null
  }

  public fetchChannelsOnDateChange = async (): Promise<void> => {
    if (this.fetchingTarget === 'channels') return

    const {date} = this.stores.routeParamsStore

    const {items: fetchedChannels, count} =
      await this.channelService.listFavoriteChannels({
        start_from: 0,
        limit: this.favoriteChannelsCurrentCount + CHANNELS_SINGLE_FETCH_COUNT
      })

    if (!fetchedChannels.length) {
      return
    }

    const {city} = this.stores.routeParamsStore

    const {items: fetchedShows} = await this.eventService.listEvents({
      channel_id: fetchedChannels.map(({channel_id: id}) => id).join(','),
      date: format(date, 'yyyy-MM-dd'),
      start_from: 0,
      limit: 1000,
      city_trans: city.nameTranslit
    })

    this.showsForFavoriteChannels.replace(
      fetchedShows.map(this.stores.showStore.formatShowForSchedule)
    )

    this.favoriteChannelsMap = {
      channels: fetchedChannels.map(this.stores.channelStore.formatChannel),
      totalCount: count,
      currentCount: fetchedChannels.length
    }
  }

  public get favoriteChannelsCurrentCount(): number {
    return this.favoriteChannelsMap.currentCount
  }

  public get favoriteChannelsTotalCount(): number {
    return this.favoriteChannelsMap.totalCount
  }

  public get favoriteChannels(): Channel[] {
    return this.favoriteChannelsMap.channels ?? []
  }

  public get favoriteChannelIds(): number[] {
    return [...this.favoriteChannelIdSet]
  }

  public get favoriteShowIds(): number[] {
    return [...this.favoriteShowIdSet]
  }

  public get likedShowIds(): number[] {
    return [...this.likedShowIdSet]
  }

  public get dislikedShowIds(): number[] {
    return [...this.dislikedShowIdSet]
  }

  // NOTE: при загрузке избранных передач, передвигается currentCount,
  // если будем несколько раз заходить в мои программы, попутно,
  // добавляя новые передачи, то при инициализации странницы избранного,
  // мы будем грузить передачи не с 0, а с n. поэтому чтобы при инициализации
  // страницы он всегда был 0, будем сбрасывать мапу
  public clearFavoriteTitlesMap = (): void => {
    this.favoriteTitlesMap = {
      totalCount: 0,
      currentCount: 0,
      shows: []
    }
  }
}

import {
  type ObservableMap,
  type IObservableArray,
  makeAutoObservable,
  observable
} from 'mobx'

import {ChannelService} from 'common/api'
import {NOT_FOUND_ERROR} from 'common/constants/errors'
import {TimeFilterKey} from 'common/constants/time-filter'
import {getIconSrcset} from 'common/utils/srcset'

import type {Channel, ApiChannel} from 'types/channel'
import type {Stores} from 'stores'
import type {HighlightCategoryKey} from 'common/constants/highlight-categories'

const CHANNELS_SINGLE_FETCH_COUNT = 12
const MIN_NUMBER_OF_RECOMMENDED_CHANNELS = 4
const MIXED_CHANNEL_CATEGORY_ID = 5

export class ChannelStore {
  isFetching: boolean

  readonly stores: Stores
  readonly channelService: ChannelService
  readonly channelsForScheduleMap: ObservableMap<
    string,
    {
      totalCount: number
      currentCount: number
      channels: Channel[]
    }
  >
  readonly channelsForPage: IObservableArray<Channel>
  scheduleDisplayParams: {
    timeFilter: TimeFilterKey
    highlightCategories: HighlightCategoryKey[]
  }

  constructor(stores: Stores) {
    this.stores = stores
    this.channelService = new ChannelService(stores.apiParams)

    this.isFetching = false
    this.channelsForScheduleMap = observable.map()
    this.channelsForPage = observable.array()
    this.scheduleDisplayParams = {
      timeFilter: TimeFilterKey.NOW,
      highlightCategories: []
    }

    makeAutoObservable(this, {stores: false})
  }

  public fetchInitialChannelsForSchedule = async (): Promise<void> => {
    const {date} = this.stores.routeParamsStore

    const channelsFetched = !!this.currentCountForSchedule

    if (!channelsFetched) {
      await this.fetchMoreChannelsForSchedule({shouldReplace: true})

      return
    }

    const showsFetched = this.channelsForSchedule.every(({id}) =>
      this.stores.showStore.showsForSchedule
        .map(({channelId}) => channelId)
        .includes(id)
    )

    if (!showsFetched) {
      await this.stores.showStore.fetchShowsByChannelIds({
        channelIds: this.channelsForSchedule.map(({id}) => id) as number[],
        date
      })
    }
  }

  public fetchMoreChannelsForSchedule = async (
    {shouldReplace}: {shouldReplace: boolean} = {shouldReplace: false}
  ): Promise<void> => {
    if (this.isFetching) return

    this.isFetching = true

    const {city, date, category} = this.stores.routeParamsStore

    const {items: fetchedChannels, count} =
      await this.channelService.listChannels({
        city_trans: city.nameTranslit,
        ...(category && {manual_category_name_trans: category}),
        start_from: this.currentCountForSchedule,
        limit: this.currentCountForSchedule + CHANNELS_SINGLE_FETCH_COUNT
      })

    this.channelsForScheduleMap.set(this.scheduleKey, {
      channels: this.channelsForSchedule.concat(
        fetchedChannels.map(this.formatChannel)
      ),
      totalCount: count,
      currentCount: this.currentCountForSchedule + fetchedChannels.length
    })

    await this.stores.showStore.fetchShowsByChannelIds({
      channelIds: fetchedChannels.map(({channel_id: id}) => id),
      date,
      shouldReplace
    })

    this.isFetching = false
  }

  public fetchChannelsForPage = async (): Promise<void> => {
    this.isFetching = true

    const {
      channel: channelNameTranslit,
      city,
      date
    } = this.stores.routeParamsStore

    const channelFetched = this.channelForPage

    if (channelNameTranslit && !channelFetched) {
      const {items: fetchedChannels} = await this.channelService.listChannels({
        display_name_trans: channelNameTranslit,
        city_trans: city.nameTranslit,
        start_from: 0,
        limit: 1
      })

      if (!fetchedChannels[0]) {
        throw new Error(NOT_FOUND_ERROR)
      }

      this.channelsForPage.push(this.formatChannel(fetchedChannels[0]))
    }

    const recommendedChannelsFetched =
      this.recommendedChannelsForPage.length >
      MIN_NUMBER_OF_RECOMMENDED_CHANNELS

    if (!recommendedChannelsFetched) {
      const {items: fetchedChannels} = await this.channelService.listChannels({
        city_trans: city.nameTranslit,
        category_id: [
          this.channelForPage?.categoryId,
          MIXED_CHANNEL_CATEGORY_ID
        ].join(','),
        start_from: 0,
        limit: 10
      })

      fetchedChannels.map((channel) => {
        if (!this.channelsForPage.some(({id}) => id === channel.channel_id)) {
          this.channelsForPage.push(this.formatChannel(channel))
        }
      })
    }

    const showsFetched = this.stores.showStore.showsForSchedule.some(
      (show) => show.channelId === this.channelForPage?.id
    )

    if (!showsFetched) {
      await this.stores.showStore.fetchShowsByChannelIds({
        channelIds: [this.channelForPage?.id] as number[],
        date
      })
    }

    this.isFetching = false
  }

  public get channelForPage(): Channel | null {
    const {channel: channelNameTranslit, city} = this.stores.routeParamsStore

    const channel = this.channelsForPage.find(
      ({nameTranslit}) => nameTranslit === channelNameTranslit
    )

    if (channel) {
      return channel
    }

    let mergedChannels: Channel[] = []

    this.channelsForScheduleMap.forEach(({channels}, key) => {
      if (~key.indexOf(city.nameTranslit)) {
        mergedChannels = mergedChannels.concat(channels)
      }
    })

    return (
      mergedChannels.find(
        (channel) => channel.nameTranslit === channelNameTranslit
      ) || null
    )
  }

  public get recommendedChannelsForPage(): Channel[] {
    const {city} = this.stores.routeParamsStore

    const uniqueChannelsMap = new Map<number, Channel>(
      this.channelsForPage.map((channel) => [channel.id, channel])
    )

    this.channelsForScheduleMap.forEach(({channels}, key) => {
      if (~key.indexOf(city.nameTranslit)) {
        channels.forEach((channel) => {
          uniqueChannelsMap.set(channel.id, channel)
        })
      }
    })

    const uniqueChannelsArr = Array.from(uniqueChannelsMap.values())

    return uniqueChannelsArr
      .filter(
        ({id, categoryId}) =>
          id !== this.channelForPage?.id &&
          (categoryId === this.channelForPage?.categoryId ||
            categoryId === MIXED_CHANNEL_CATEGORY_ID)
      )
      .sort(({categoryId}) =>
        categoryId === this.channelForPage?.categoryId ? -1 : 1
      )
  }

  public updateScheduleDisplayParams = (
    params: Partial<ChannelStore['scheduleDisplayParams']>
  ): void => {
    if (!params) return

    this.scheduleDisplayParams = {
      ...this.scheduleDisplayParams,
      ...params
    }
  }

  public get channelsForSchedule(): Channel[] {
    return this.channelsForScheduleMap.get(this.scheduleKey)?.channels || []
  }

  public get totalCountForSchedule(): number {
    return this.channelsForScheduleMap.get(this.scheduleKey)?.totalCount ?? 0
  }

  private get currentCountForSchedule(): number {
    return this.channelsForScheduleMap.get(this.scheduleKey)?.currentCount ?? 0
  }

  private get scheduleKey(): string {
    const {city, category} = this.stores.routeParamsStore

    return [city.nameTranslit, category].filter(Boolean).join('::')
  }

  public formatChannel = (channel: ApiChannel): Channel => ({
    id: channel.channel_id,
    categoryId: channel.category_id,
    baseChannelId: channel.base_channel_id,
    name: channel.display_name,
    nameTranslit: channel.display_name_trans,
    iconSrc: channel.icon ?? '',
    iconSrcset: getIconSrcset(channel.resized_icons),
    description: channel.description ?? '',
    descriptionShort: channel.small_description ?? '',
    commentCount: channel.comment_count
  })

  public serialize = (): Pick<
    ChannelStore,
    'channelsForScheduleMap' | 'channelsForPage'
  > => ({
    channelsForScheduleMap: this.channelsForScheduleMap,
    channelsForPage: this.channelsForPage
  })

  public hydrate = ({
    channelsForScheduleMap,
    channelsForPage
  }: ReturnType<ChannelStore['serialize']>): void => {
    this.channelsForScheduleMap.replace(channelsForScheduleMap)
    this.channelsForPage.replace(channelsForPage)
  }
}

import React, {useState, useRef, useEffect, useMemo} from 'react'

import debounce from 'lodash.debounce'
import clsx from 'clsx'
import type {City} from 'types/cities'
import type {ChannelCategory} from 'types/category'

import {CONSTANT_CHANNEL_CATEGORIES} from 'common/constants/category-navigation'
import {getUrlForCategory} from 'common/utils/url'

import {NavLink} from 'common/components/nav-link'
import {DropdownWrapper, Dropdown} from 'common/components/dropdown'
import {MoreIcon} from 'common/components/icons'

import styles from './styles.module.css'

const BUTTON_MORE_WIDTH = 55
const CALCULATE_INDEX_TIMEOUT = 50

interface CategoryNavigationDesktopProps {
  categories: ChannelCategory[]
  routeParams: {city: City; date: Date}
  top100?: string
}

export const CategoryNavigationDesktop: React.FC<
  CategoryNavigationDesktopProps
> = ({categories: channelCategories, routeParams, top100}) => {
  const categories = CONSTANT_CHANNEL_CATEGORIES.map((category) => ({
    ...category,
    nameTranslit: category.nameTranslit.replace(
      ':city',
      routeParams.city.nameTranslit
    )
  })).concat(channelCategories)

  const menuRef = useRef<HTMLDivElement>(null)
  const hiddenBlockRef = useRef<HTMLDivElement>(null)

  const [firstHiddenIndex, setFirstHiddenIndex] = useState<number>(
    categories.length
  )

  const withMoreButton = firstHiddenIndex !== categories.length

  const calculateLastVisibleIndex = (): void => {
    if (hiddenBlockRef?.current && menuRef?.current) {
      const {right: navigationRight} = menuRef.current.getBoundingClientRect()

      const menuOffsetRight = navigationRight - BUTTON_MORE_WIDTH

      const categoryNodes = Array.from(hiddenBlockRef.current.children)

      const lastVisibleIndex = categoryNodes.findIndex((element) => {
        const {right: elementOffsetRight} = element.getBoundingClientRect()

        return menuOffsetRight < elementOffsetRight
      })

      setFirstHiddenIndex(
        lastVisibleIndex > 0 ? lastVisibleIndex : categories.length
      )
    }
  }

  useEffect(() => {
    const onResize = debounce(
      calculateLastVisibleIndex,
      CALCULATE_INDEX_TIMEOUT
    )

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  useEffect(() => {
    calculateLastVisibleIndex()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const hiddenCategories = useMemo(
    () =>
      categories
        .slice(firstHiddenIndex)
        .map(({id, name, nameTranslit}, index) => (
          <NavLink
            key={id}
            onClick={(): void => setIsDropdownOpen(false)}
            to={getUrlForCategory({
              city: routeParams.city,
              categoryTranslit: nameTranslit
            })}
            className={({isActive}) =>
              clsx(styles.link, isActive && styles.active)
            }
            data-tv-desktop={`${top100}::top100nav_menu::nav_item::${
              index + 1
            }`}>
            {name}
          </NavLink>
        )),
    [categories, firstHiddenIndex, routeParams, top100]
  )

  const visibleCategories = useMemo(
    () =>
      categories
        .slice(0, firstHiddenIndex)
        .map(({id, name, nameTranslit}, index) => (
          <NavLink
            key={id}
            to={
              index < CONSTANT_CHANNEL_CATEGORIES.length
                ? nameTranslit
                : getUrlForCategory({
                    city: routeParams.city,
                    categoryTranslit: nameTranslit
                  })
            }
            className={({isActive}) =>
              clsx(styles.link, isActive && styles.active)
            }
            data-tv-desktop={`${top100}::nav_menu::nav_item::${index + 1}`}>
            {name}
          </NavLink>
        )),

    [categories, firstHiddenIndex, routeParams, top100]
  )

  return (
    <div className={styles.root}>
      <div ref={menuRef} className={styles.menu} data-testid="menu-container">
        <div className={styles.menuHidden}>
          <div
            ref={hiddenBlockRef}
            className={styles.hiddenList}
            data-testid="hidden-list">
            {categories.map(({id, name}) => (
              <span key={id} className={styles.link}>
                {name}
              </span>
            ))}
          </div>
        </div>
        <div className={styles.list}>
          {visibleCategories}
          {withMoreButton && (
            <DropdownWrapper className={styles.dropdown}>
              <button
                type="button"
                className={styles.moreButton}
                onClick={() => setIsDropdownOpen((open) => !open)}>
                <span>Ещё</span>
                <MoreIcon />
              </button>
              <Dropdown
                display="bright"
                width={205}
                isOpen={isDropdownOpen}
                onClose={() => setIsDropdownOpen(false)}>
                <div className={styles.dropdownContent}>{hiddenCategories}</div>
              </Dropdown>
            </DropdownWrapper>
          )}
        </div>
      </div>
    </div>
  )
}

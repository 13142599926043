import type {ChannelCategory} from 'types/category'

import {Urls} from 'common/constants/urls'

export const CONSTANT_CHANNEL_CATEGORIES: Pick<
  ChannelCategory,
  'name' | 'nameTranslit' | 'id'
>[] = [
  {
    name: 'Главная',
    nameTranslit: Urls.MAIN,
    id: Urls.MAIN
  },
  {
    name: 'Моя программа',
    nameTranslit: Urls.PERSONAL,
    id: Urls.PERSONAL
  },
  {
    name: 'Премьеры недели',
    nameTranslit: Urls.WEEK,
    id: Urls.WEEK
  },
  {
    name: 'Выходные',
    nameTranslit: Urls.WEEKEND,
    id: Urls.WEEKEND
  }
]

/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import {useViewport} from '@rambler-id/react'
import clsx from 'clsx'

import {DESKTOP_BREAKPOINT} from 'common/constants/viewport'
import {Blocks} from './blocks'

import styles from './styles.module.css'

const BLOCKS_COUNT = 12

export const MainPage: React.FC = () => {
  const {isDesktop} = useViewport([DESKTOP_BREAKPOINT])

  return (
    <div>
      <div className={clsx(!isDesktop && styles.tablet)}>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.leftColumn}>
              <div className={styles.row}>
                <div
                  className={clsx(
                    styles.pageTitle,
                    styles.animated,
                    styles.item
                  )}
                />
                <div
                  className={clsx(styles.select, styles.animated, styles.item)}
                />
              </div>
              <div className={clsx(styles.navigation, styles.animated)} />
              <div className={styles.categories}>
                <Blocks count={4} />
              </div>
              <div className={styles.channels}>
                {new Array(isDesktop ? 6 : 4).fill(0).map((_, index) => (
                  <div className={styles.channel} key={index}>
                    <div
                      className={clsx(
                        styles.channelTitle,
                        styles.animated,
                        styles.item
                      )}
                    />
                    <div className={styles.channelRows}>
                      <Blocks count={BLOCKS_COUNT} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={clsx(styles.registration, styles.animated)} />
              <div className={clsx(styles.news, styles.animated)} />
              {isDesktop && (
                <div
                  className={clsx(styles.news, styles.short, styles.animated)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import {isSSR} from '@rambler-id/react'
import {clientRuntime} from './client-runtime'

/**
 * NOTE: env vars used only server-side
 */
export const {APP_NAME, LOGGING} = process.env
/**
 * NOTE: env vars used both server-side and client-side
 */
export const {
  NODE_ENV,
  VERSION,

  TV_API_ORIGIN,
  TV_API_PREFIX,
  RCM_API_ORIGIN,

  SENTRY_DSN,
  SENTRY_SAMPLE_RATE,
  SENTRY_TRACES_SAMPLE_RATE,

  METRICS,
  METRICS_SAMPLE_RATE
} = isSSR || process.env.NODE_ENV === 'test' ? process.env : clientRuntime.env

/* eslint-disable sonarjs/no-duplicate-string */
import {lazy, type PageRoute} from '@rambler-tech/react-toolkit/client'
import {Urls} from 'common/constants/urls'
import {Skeleton} from 'desktop/components/skeleton'

const MainPageDesktop = lazy(
  () => import(/* webpackChunkName: "desktop.main" */ 'desktop/pages/main')
)
const FavoritePageDesktop = lazy(
  () =>
    import(/* webpackChunkName: "desktop.favorite" */ 'desktop/pages/favorite')
)
const ChannelPageDesktop = lazy(
  () =>
    import(/* webpackChunkName: "desktop.channel" */ 'desktop/pages/channel')
)
const ShowPageDesktop = lazy(
  () => import(/* webpackChunkName: "desktop.show" */ 'desktop/pages/show')
)
const PremierePageDesktop = lazy(
  () =>
    import(/* webpackChunkName: "desktop.premiere" */ 'desktop/pages/premiere')
)

export const routes: (PageRoute & {chunkName: string})[] = [
  {
    path: Urls.MAIN,
    Component: MainPageDesktop,
    Fallback: Skeleton.MainPage,
    chunkName: 'desktop.main'
  },
  {
    path: Urls.CITY,
    Component: MainPageDesktop,
    Fallback: Skeleton.MainPage,
    chunkName: 'desktop.main'
  },
  {
    path: Urls.YESTERDAY,
    Component: MainPageDesktop,
    Fallback: Skeleton.MainPage,
    chunkName: 'desktop.main'
  },
  {
    path: Urls.TODAY,
    Component: MainPageDesktop,
    Fallback: Skeleton.MainPage,
    chunkName: 'desktop.main'
  },
  {
    path: Urls.TOMORROW,
    Component: MainPageDesktop,
    Fallback: Skeleton.MainPage,
    chunkName: 'desktop.main'
  },
  {
    path: Urls.WEEK,
    Component: PremierePageDesktop,
    Fallback: Skeleton.PremierePage,
    chunkName: 'desktop.premiere'
  },
  {
    path: Urls.WEEKEND,
    Component: PremierePageDesktop,
    Fallback: Skeleton.PremierePage,
    chunkName: 'desktop.premiere'
  },
  {
    path: Urls.DATE,
    Component: MainPageDesktop,
    Fallback: Skeleton.MainPage,
    chunkName: 'desktop.main'
  },
  {
    path: Urls.PERSONAL,
    Component: FavoritePageDesktop,
    Fallback: Skeleton.FavoritePage,
    chunkName: 'desktop.favorite'
  },
  {
    path: Urls.CATEGORY,
    Component: MainPageDesktop,
    Fallback: Skeleton.MainPage,
    chunkName: 'desktop.main'
  },
  {
    path: Urls.CHANNEL,
    Component: ChannelPageDesktop,
    Fallback: Skeleton.ChannelPage,
    chunkName: 'desktop.channel'
  },
  {
    path: Urls.CHANNEL_DATE,
    Component: ChannelPageDesktop,
    Fallback: Skeleton.ChannelPage,
    chunkName: 'desktop.channel'
  },
  {
    path: Urls.SHOW,
    Component: ShowPageDesktop,
    Fallback: Skeleton.ShowPage,
    chunkName: 'desktop.show'
  },
  {
    path: Urls.EPISODE,
    Component: ShowPageDesktop,
    chunkName: 'desktop.episode'
  }
]

import {format} from 'date-fns'
import {isToday, isTomorrow, isYesterday} from 'common/utils/date'
import {
  TODAY_SLUG,
  TOMORROW_SLUG,
  YESTERDAY_SLUG,
  Urls
} from 'common/constants/urls'
import type {City} from 'types/cities'

/**
 * Обрезаем trailing slash из урла
 */
export const clearTrailingSlash = (pathname = '/'): string =>
  pathname === '/' ? pathname : pathname.replace(/\/+$/, '')

/**
 * Формируем ссылку для даты
 */
const getUrlForDate = (date: Date): string => {
  if (isYesterday(date)) {
    return YESTERDAY_SLUG
  }

  if (isToday(date)) {
    return TODAY_SLUG
  }

  if (isTomorrow(date)) {
    return TOMORROW_SLUG
  }

  return `/${format(date, 'y-MM-dd')}/`
}

/**
 * Формируем ссылку для даты в навигации
 * @param city - текущий город в роутинге
 * @param date - дата
 */
export const getUrlForMainPage = ({
  city,
  date
}: {
  city: City
  date: Date
}): string => {
  return `/${city.nameTranslit}${getUrlForDate(date)}`
}

export const getUrlForCityWithAbsoluteDate = ({
  city,
  date
}: {
  city: City
  date: Date
}): string => {
  return `/${city.nameTranslit}/${format(date, 'y-MM-dd')}/`
}

/**
 * Формируем ссылку для категории
 * @param city - текущий город в роутинге
 * @param date - дата
 * @param categoryTranslit - категория
 */
export const getUrlForCategory = ({
  city,
  date,
  categoryTranslit
}: {
  city: City
  date?: Date
  categoryTranslit: string
}): string =>
  `/${city.nameTranslit}/category/${categoryTranslit}/${
    date ? `${format(date, 'y-MM-dd')}/` : ''
  }`

/**
 * Формируем ссылку для канала
 * @param city - текущий город в роутинге
 * @param date - дата
 * @param channelTranslit - название канала
 */
export const getUrlForChannel = ({
  city,
  channelTranslit,
  date
}: {
  city: City
  date?: Date
  channelTranslit: string
}): string =>
  `/${city.nameTranslit}/channel/${channelTranslit}/${
    date ? `${format(date, 'y-MM-dd')}/` : ''
  }`

/**
 * Формируем ссылку из текущего pathname с добавлением даты в гет-параметры
 * @param pathname - текущий pathname
 * @param date - дата
 */
const getUrlWithSearchParams = ({
  pathname,
  date
}: {
  pathname: string
  date: Date
}): string => `${pathname}?date=${format(date, 'y-MM-dd')}`

/**
 * Формируем ссылку для передачи
 * @param city - текущий город в роутинге
 * @param date - дата
 * @param channelTranslit - название канала
 * @param channelId - идентификатор канала
 * @param titleId - идентификатор передачи (тайтла)
 */
export const getUrlForShow = ({
  channelTranslit,
  channelId,
  titleTranslit,
  city,
  titleId
}: {
  channelTranslit: string
  channelId: number
  titleTranslit: string
  city: City
  titleId: number
}): string =>
  `/${city.nameTranslit}/${channelTranslit}/${titleTranslit}-${titleId}-${channelId}/`

/**
 * Формируем ссылку для эпизода (серии сериала)
 * @param city - текущий город в роутинге
 * @param date - дата
 * @param channelTranslit - название канала
 * @param channelId - идентификатор канала
 * @param titleId - идентификатор передачи (тайтла)
 * @param episodeId - идентификатор эпизода (серии)
 */
export const getUrlForEpisode = ({
  channelTranslit,
  channelId,
  titleTranslit,
  city,
  titleId,
  episodeId
}: {
  channelTranslit: string
  channelId: number
  titleTranslit: string
  city: City
  titleId: number
  episodeId: string
}): string =>
  `/${city.nameTranslit}/${channelTranslit}/${titleTranslit}-${titleId}-${channelId}/${episodeId}/`

/**
 * Формируем ссылку для города
 * @param city - текущий город в роутинге
 */
export const getUrlForCity = ({city}: {city: City}): string =>
  `/${city.nameTranslit}/`

/**
 * Формируем ссылку на страницу премьер недели
 * @param city - текущий город в роутинге
 */
export const getUrlForWeekPremieres = ({city}: {city: City}): string =>
  Urls.WEEK.replace(':city', city.nameTranslit)

/**
 * Формируем ссылку на страницу премьер выходных
 * @param city - текущий город в роутинге
 */
export const getUrlForWeekendPremieres = ({city}: {city: City}): string =>
  Urls.WEEKEND.replace(':city', city.nameTranslit)

/**
 * Формируем ссылку для <DayNavigation> и <DayNavigationMobile>
 */
export const getUrlForDayNavigation = ({
  city,
  date,
  pathname,
  channel,
  category,
  dateInSearchParams
}: {
  city: City
  date: Date
  pathname: string
  channel?: string
  category?: string
  dateInSearchParams?: boolean
}): string => {
  let url = getUrlForMainPage({city, date})

  if (dateInSearchParams) {
    url = getUrlWithSearchParams({
      pathname,
      date
    })
  } else if (channel) {
    url = getUrlForChannel({
      city,
      channelTranslit: channel,
      date: isToday(date) ? undefined : date
    })
  } else if (category) {
    url = getUrlForCategory({
      city,
      categoryTranslit: category,
      date: isToday(date) ? undefined : date
    })
  }

  return url
}

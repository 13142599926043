import {
  type Metric,
  onTTFB,
  onFCP,
  onLCP,
  onFID,
  onCLS,
  onINP
} from 'web-vitals'
import {METRICS_SAMPLE_RATE} from 'common/utils/env'

const SEND_METRICS_TIMEOUT = 10

interface InitWebVitalsMetricsOptions {
  pathname: string
  deviceType: string
}

export const initWebVitalsMetrics = ({
  pathname,
  deviceType
}: InitWebVitalsMetricsOptions): void => {
  /* NOTE: Чтобы уменьшить нагрузку, собираем метрики только для процента пользователей */
  if (Math.random() > Number(METRICS_SAMPLE_RATE)) {
    return
  }

  const queue = new Set<Metric>()

  const addToQueue = (metric: Metric): void => {
    queue.add(metric)
  }

  const flushSendQueue = (): void => {
    if (queue.size > 0) {
      const body = JSON.stringify({
        pathname,
        deviceType,
        metrics: [...queue]
      })

      if (navigator.sendBeacon) {
        navigator.sendBeacon('/metrics/', body)
      } else {
        fetch('/metrics/', {
          body,
          method: 'POST',
          keepalive: true
        })
      }

      queue.clear()
    }
  }

  onTTFB(addToQueue)
  onFCP(addToQueue)
  onLCP(addToQueue)
  onFID(addToQueue)
  onCLS(addToQueue)
  onINP(addToQueue)

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      setTimeout(() => flushSendQueue(), SEND_METRICS_TIMEOUT)
    }
  })
}

import {useState, useEffect} from 'react'

export const usePageVisibility = () => {
  const [documentVisibilityState, setDocumentVisibilityState] =
    useState<DocumentVisibilityState | null>(null)

  useEffect(() => {
    const handleVisibility = () => {
      setDocumentVisibilityState(document.visibilityState)
    }

    document.addEventListener('visibilitychange', handleVisibility)

    return () =>
      document.removeEventListener('visibilitychange', handleVisibility)
  }, [])

  return {documentVisibilityState}
}

import type {ApiMetaTag} from 'types/meta-tags'
import {Endpoints} from './request'
import {ApiService} from './service'

export class MetaTagsService extends ApiService {
  /**
   * Получение списка настроек мета-тегов
   */
  listMetaTags(): Promise<{items: ApiMetaTag[]}> {
    return this.request<{items: ApiMetaTag[]}>(Endpoints.LIST_META_TAGS)
  }
}

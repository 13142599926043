import {type IObservableArray, makeAutoObservable, observable} from 'mobx'
import {format, isEqual} from 'date-fns'
import {ru} from 'date-fns/locale'

import {MetaTagsService} from 'common/api'
import {Urls} from 'common/constants/urls'
import {MetaTagIds} from 'common/constants/meta-tags'

import type {MetaTag, ApiMetaTag} from 'types/meta-tags'
import type {Stores} from 'stores'
import type {City} from 'types/cities'
import type {Channel} from 'types/channel'
import type {ShowForPage} from 'types/show'
import type {Episode} from 'types/episode'
import {EventCategoryId} from 'common/constants/event-category'

export class MetaTagsStore {
  isFetching: boolean
  readonly stores: Stores
  readonly metaTagsService: MetaTagsService
  readonly templates: IObservableArray<MetaTag>

  constructor(stores: Stores) {
    this.stores = stores
    this.metaTagsService = new MetaTagsService(stores.apiParams)

    this.templates = observable.array<MetaTag>()
    this.isFetching = true

    makeAutoObservable(this, {stores: false})
  }

  public fetchMetaTagTemplates = async (): Promise<void> => {
    const templatesFetched = !!this.templates.length

    if (templatesFetched) {
      return
    }

    try {
      this.isFetching = true

      const {items: templates} = await this.metaTagsService.listMetaTags()

      this.templates.replace(templates.map(this.formatMetaTag))
    } finally {
      this.isFetching = false
    }
  }

  public get metaTag(): MetaTag | null {
    const [urlKey] =
      Object.entries(Urls).find(
        ([, value]) => value === this.stores.routeParamsStore.routeMatch
      ) || []

    if (!urlKey) {
      return null
    }

    let template: MetaTag | null = null

    if (urlKey === 'EPISODE' && this.stores.showStore.episodeForPopup) {
      const templateId = this.getEpisodeTemplateId()

      template = this.templates.find(({id}) => id === templateId) ?? null
    } else if (urlKey === 'CATEGORY' && this.stores.categoryStore.category) {
      const {
        seoTitle: title,
        seoDescription: description,
        seoH1: h1,
        seoH2: h2,
        seoText: text
      } = this.stores.categoryStore.category

      template = {id: '', title, description, h1, h2, text}
    } else {
      const metaTagId = MetaTagIds[urlKey as keyof typeof MetaTagIds]

      template = this.templates.find(({id}) => id === metaTagId) ?? null
    }

    if (!template) {
      return null
    }

    return Object.entries(template).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: this.transformTemplate({
          template: value,
          city: this.stores.routeParamsStore.city,
          date:
            urlKey === 'EPISODE' && this.stores.showStore.episodeForPopup
              ? this.stores.showStore.episodeForPopup.start
              : this.stores.routeParamsStore.date,
          channel: this.stores.channelStore.channelForPage,
          show: this.stores.showStore.showForPage,
          episode: this.stores.showStore.episodeForPopup
        })
      }),
      template
    )
  }

  private getEpisodeTemplateId(): void | string {
    if (!this.stores.showStore.episodeForPopup) {
      return
    }

    const {season, category, schedule, start} =
      this.stores.showStore.episodeForPopup

    const isSerialWithSeasons = !!season

    if (isSerialWithSeasons) {
      return MetaTagIds.EPISODE
    }

    const isFilmWithoutRepeats =
      category?.id === EventCategoryId.FILM &&
      !Object.values(schedule).some((date) => !isEqual(date, start))

    if (isFilmWithoutRepeats) {
      return MetaTagIds.FILM
    }

    return MetaTagIds.MIXED_EVENT
  }

  private formatMetaTag = ({
    metatag_id: id,
    label: _,
    text,
    h1,
    h2,
    ...rest
  }: ApiMetaTag): MetaTag => ({
    id,
    text: text ?? '',
    h1: h1 ?? '',
    h2: h2 ?? '',
    ...rest
  })

  private transformTemplate = (params: {
    template: string
    city: City
    date: Date
    channel: Channel | null
    show: ShowForPage | null
    episode: Episode | null
  }): string => {
    return params.template
      .replaceAll('{city}', params.city.name)
      .replaceAll('{city_inflected}', params.city.nameInflected)
      .replaceAll('{time}', format(params.date, 'HH:mm', {locale: ru}))
      .replaceAll('{date}', format(params.date, 'd MMMM y года', {locale: ru}))
      .replaceAll('{week_day}', format(params.date, 'EEEE', {locale: ru}))
      .replaceAll('{channel_name}', params.channel?.name ?? '')
      .replaceAll('{show_name}', params.show?.title ?? '')
      .replaceAll('{episode_name}', params.episode?.subTitle ?? '')
      .replaceAll('{episode_season}', String(params.episode?.season ?? ''))
      .replaceAll('{episode_series}', String(params.episode?.series ?? ''))
  }

  public serialize = (): Pick<MetaTagsStore, 'templates'> => ({
    templates: this.templates
  })

  public hydrate = ({
    templates
  }: ReturnType<MetaTagsStore['serialize']>): void => {
    this.templates.replace(templates)
  }
}

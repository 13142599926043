import {useLocation, useParams} from 'react-router-dom'
import {useEffect} from 'react'

import type {Stores} from 'stores'
import {getTodayDate, isSameDay} from './date'
import {usePageVisibility} from './use-page-visibility'

export const useRefetchOnPageFocus = (stores: Stores) => {
  const {date} = useParams()
  const location = useLocation()
  const {documentVisibilityState} = usePageVisibility()

  const {
    routeParamsStore,
    channelStore,
    favoriteStore,
    premiereStore,
    showStore
  } = stores

  useEffect(() => {
    /** NOTE условие для повторной выборки даных https://jira.rambler-co.ru/browse/TVPROG-251 */
    const isRefetchNeeded =
      !isSameDay(routeParamsStore.date, getTodayDate()) &&
      !date &&
      documentVisibilityState === 'visible'

    if (!isRefetchNeeded) {
      return
    }

    ;

(async () => {
      const {pageName} = routeParamsStore

      routeParamsStore.updateDateFromUrl({pathname: ''})

      if (pageName === 'main') {
        await channelStore.fetchInitialChannelsForSchedule()
      }

      if (pageName === 'personal') {
        favoriteStore.clearFavoriteTitlesMap()

        await Promise.all([
          favoriteStore.fetchFavoriteChannels(),
          favoriteStore.fetchFavoriteShows({isInitialFetch: true})
        ])
      }

      if (pageName === 'week' || pageName === 'weekend') {
        await Promise.all([
          channelStore.fetchInitialChannelsForSchedule(),
          premiereStore.fetchInitialPremieres({
            isWeekend: !!~location.pathname.indexOf('/weekend')
          })
        ])
      }

      if (pageName.startsWith('channel')) {
        await channelStore.fetchChannelsForPage()
      }

      if (pageName.startsWith('episode') || pageName.startsWith('show')) {
        await Promise.all([
          channelStore.fetchChannelsForPage(),
          showStore.fetchShowsForPage(),
          showStore.fetchEpisode()
        ])
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentVisibilityState])
}

import {
  createUpdateOptions,
  createYandexMetrikaHit,
  createTrackPageview
} from '@rambler-id/metrics'
import {PUBLISHER_SCOPE} from './ad'

const TOP100_PORTAL_COUNTER_ID = 7728281
const TOP100_COUNTER_ID = 213035
const YM_GENERAL_PORTAL_COUNTER_ID = 26649402
const YM_COUNTER_ID = 29583985

export const YANDEX_METRICA_INIT = {
  counterKey: YM_COUNTER_ID,
  params: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  }
}

export const YANDEX_METRICA_GENERAL_PORTAL_INIT = {
  counterKey: YM_GENERAL_PORTAL_COUNTER_ID,
  params: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: false
  }
}

export const TNS_SBER_INIT = {
  account: 'rambler_ad',
  tmsec: 'rambler_cid1100104-posid2155079'
}

export const TNS_RAMBLER_INIT = {
  account: 'rambler_ad',
  tmsec: 'rambler_cid1100108-posid2155111'
}

const TOP100_INIT_OPTIONS_PARAMS = {
  pub_scope: PUBLISHER_SCOPE
}

export const TOP100_INIT_OPTIONS_DESKTOP = {
  counterKey: [TOP100_COUNTER_ID, TOP100_PORTAL_COUNTER_ID],
  dataset: ['cerber-topline', 'cerber-head', 'cerber', 'tv-desktop'],
  params: TOP100_INIT_OPTIONS_PARAMS
}

export const TOP100_INIT_OPTIONS_MOBILE = {
  counterKey: [TOP100_COUNTER_ID, TOP100_PORTAL_COUNTER_ID],
  dataset: ['cerber-topline-mob', 'cerber-mhead', 'cerber', 'tv-mobile'],
  params: TOP100_INIT_OPTIONS_PARAMS
}

export const updateTop100Options = createUpdateOptions([
  TOP100_COUNTER_ID,
  TOP100_PORTAL_COUNTER_ID
])

export const top100TrackPageview = createTrackPageview(TOP100_COUNTER_ID)

export const top100TrackPageviewPortal = createTrackPageview(
  TOP100_PORTAL_COUNTER_ID
)

export const ymHit = createYandexMetrikaHit(YM_COUNTER_ID)

export const ymHitPortal = createYandexMetrikaHit(YM_GENERAL_PORTAL_COUNTER_ID)

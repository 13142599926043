import {
  startOfDay,
  startOfToday,
  isEqual,
  isAfter,
  isBefore,
  addHours,
  eachDayOfInterval,
  subDays,
  addDays
} from 'date-fns'

const AIR_OFFSET = 5
const TWO_WEEKS = 14

export const getDayDate = (date: Date): Date =>
  addHours(startOfDay(date), AIR_OFFSET)

export const getTodayDate = (): Date => {
  // NOTE: добавляем 5 часов, так как эфирный день расчитывается с 05:00
  const today = addHours(startOfToday(), AIR_OFFSET)

  // NOTE: если текущее время меньше, считаем,
  // что все еще продолжается вчерашний эфирный день
  if (today > new Date()) {
    return subDays(today, 1)
  }

  return today
}

export const getYesterdayDate = (): Date => subDays(getTodayDate(), 1)
export const getTomorrowDate = (): Date => addDays(getTodayDate(), 1)

export const getNavigationDates = (): Date[] => {
  const today = getTodayDate()

  return eachDayOfInterval({
    start: subDays(today, TWO_WEEKS),
    end: addDays(today, TWO_WEEKS)
  }).map((date) => addHours(date, AIR_OFFSET))
}

export const isSameDay = (date: Date, compareDate: Date): boolean =>
  isEqual(date, compareDate) ||
  (isAfter(date, compareDate) && isBefore(date, addDays(compareDate, 1)))

export const isToday = (date: Date): boolean => isSameDay(date, getTodayDate())

export const isYesterday = (date: Date): boolean =>
  isSameDay(date, getYesterdayDate())

export const isTomorrow = (date: Date): boolean =>
  isSameDay(date, getTomorrowDate())

export const isPast = (date: Date): boolean => isBefore(date, new Date())

export const isFuture = (date: Date): boolean => isAfter(date, new Date())

const SECONDS_IN_MINUTE = 60
const ONE_MILLISECOND = 1000
const MIN_TWO_DIGIT_NUMBER = 10

export const getDateISOString = (date: Date, utc: number): string => {
  const localISOTime = new Date(
    date.getTime() -
      date.getTimezoneOffset() * ONE_MILLISECOND * SECONDS_IN_MINUTE
  ).toISOString()

  const offset = -(utc * SECONDS_IN_MINUTE)

  let offsetHours = `${Math.abs(offset / SECONDS_IN_MINUTE)}`
  let offsetMins = `${Math.abs(offset % SECONDS_IN_MINUTE)}`

  let formattedOffset

  if (Number(offsetHours) < MIN_TWO_DIGIT_NUMBER) {
    offsetHours = `0${offsetHours}`
  }

  if (Number(offsetMins) < MIN_TWO_DIGIT_NUMBER) {
    offsetMins = `0${offsetMins}`
  }

  if (offset < 0) {
    formattedOffset = `+${offsetHours}:${offsetMins}`
  }

  if (offset > 0) {
    formattedOffset = `-${offsetHours}:${offsetMins}`
  }

  return formattedOffset
    ? localISOTime.replace(/Z/g, formattedOffset)
    : localISOTime
}

export const getRelativeDateLabel = (date: Date): string | null => {
  let relativeDate = null

  if (isYesterday(date)) {
    relativeDate = 'Вчера'
  } else if (isToday(date)) {
    relativeDate = 'Сегодня'
  } else if (isTomorrow(date)) {
    relativeDate = 'Завтра'
  }

  return relativeDate
}

export const isDateString = (value: string): boolean => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/

  return dateRegex.test(value)
}

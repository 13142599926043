import {
  hydrateFromStream,
  getState,
  TransitionMode
} from '@rambler-tech/react-toolkit/client'
import {StateKeys} from 'common/constants'
import {initSentry} from 'common/utils/sentry'
import {getRouteInfo} from 'common/utils/route-info'
import {initWebVitalsMetrics} from 'common/utils/metrics'
import {configureMobX} from 'common/utils/mobx'
import {clientRuntime} from 'common/utils/client-runtime'
import {routes} from 'desktop/routes'

import {DocumentCommon as Document} from 'common/components/document'
import {LayoutCommonDesktop as Layout} from 'desktop/components/layout'

import {Stores} from 'stores'
import type {CategoryStore} from 'stores/category'
import type {ChannelStore} from 'stores/channel'
import type {ShowStore} from 'stores/show'
import type {NewsStore} from 'stores/news'
import type {MetaTagsStore} from 'stores/meta-tags'
import type {RouteParamsStore} from 'stores/route-params'
import type {PremiereStore} from 'stores/premiere'
import {setupRCM} from 'common/utils/recommender'

const runtime = {...clientRuntime}
const initialRoute = getRouteInfo(runtime.pathname, routes)

initSentry(runtime)
initWebVitalsMetrics({...runtime, pathname: initialRoute?.path ?? ''})
configureMobX()

setupRCM(runtime.ruid)

const stores = new Stores()

const categoryStoreState = getState<ReturnType<CategoryStore['serialize']>>(
  StateKeys.CATEGORY_STORE
)
const channelStoreState = getState<ReturnType<ChannelStore['serialize']>>(
  StateKeys.CHANNEL_STORE
)
const showStoreState = getState<ReturnType<ShowStore['serialize']>>(
  StateKeys.SHOW_STORE
)
const newsStoreState = getState<ReturnType<NewsStore['serialize']>>(
  StateKeys.NEWS_STORE
)
const metaTagsStoreState = getState<ReturnType<MetaTagsStore['serialize']>>(
  StateKeys.META_TAGS_STORE
)
const routeParamsStoreState = getState<
  ReturnType<RouteParamsStore['serialize']>
>(StateKeys.ROUTE_PARAMS_STORE)
const premiereStoreState = getState<ReturnType<PremiereStore['serialize']>>(
  StateKeys.PREMIERE_STORE
)

stores.categoryStore.hydrate(categoryStoreState)
stores.channelStore.hydrate(channelStoreState)
stores.showStore.hydrate(showStoreState)
stores.newsStore.hydrate(newsStoreState)
stores.metaTagsStore.hydrate(metaTagsStoreState)
stores.routeParamsStore.hydrate(routeParamsStoreState)
stores.premiereStore.hydrate(premiereStoreState)

hydrateFromStream({
  routes,
  Document,
  Layout,
  runtime,
  stores,
  scrollToTop: false,
  transition: TransitionMode.WAIT_FOR_DATA
})

if (module.hot) {
  module.hot.accept()
}

import React from 'react'

import clsx from 'clsx'
import styles from './styles.module.css'

export const Blocks: React.FC<{count: number}> = ({count}) =>
  new Array(count)
    .fill('')
    .map((_, index) => (
      <div key={index} className={clsx(styles.animated, styles.item)} />
    ))

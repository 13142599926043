import type {PageRoute} from '@rambler-tech/react-toolkit/client'
import {matchRoutes} from 'react-router-dom'

type RouteInfo = PageRoute & {chunkName: string}

export const getRouteInfo = (
  pathname: string,
  routes: RouteInfo[]
): RouteInfo | undefined => {
  const [match] = matchRoutes<any>(routes, pathname) ?? []

  return match?.route
}

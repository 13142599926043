import type {ApiChannelCategory} from 'types/category'
import {Endpoints} from './request'
import {ApiService} from './service'

export class CategoryService extends ApiService {
  /**
   * Получение категорий каналов
   */
  listManualChannelCategories(): Promise<{
    items: ApiChannelCategory[]
  }> {
    return this.request<{items: ApiChannelCategory[]}>(
      Endpoints.LIST_MANUAL_CHANNEL_CATEGORIES
    )
  }
}

declare global {
  interface Window {
    begun_new_callbacks: {
      block: {
        drawComplete(result: {viewTypes: string}): void
      }
      page: {
        spaUpdate(): void
      }
    }[]
    'DSP-Branding': {
      banners: Record<string, {removeBanner(): void}>
    }
  }
}

export const branderScript = (): void => {
  const WITH_BRANDING_CLASS_NAME = 'with-branding'
  const WITH_MINI_BILLBOARD_CLASS_NAME = 'with-mini-billboard'
  const DSP_BRANDING = 'DSP-Branding'

  window.begun_new_callbacks = window.begun_new_callbacks || []
  window.begun_new_callbacks.push({
    block: {
      drawComplete: (result) => {
        if (result && result.viewTypes) {
          if (result.viewTypes.indexOf('Branding') !== -1) {
            document.body.classList.add(WITH_BRANDING_CLASS_NAME)
          } else if (result.viewTypes.indexOf('Graph970x100') !== -1) {
            document.body.classList.add(WITH_MINI_BILLBOARD_CLASS_NAME)
          }
        }
      }
    },
    page: {
      spaUpdate: () => {
        document.body.classList.remove(WITH_BRANDING_CLASS_NAME)
        document.body.classList.remove(WITH_MINI_BILLBOARD_CLASS_NAME)

        if (typeof window[DSP_BRANDING] !== 'undefined') {
          const banners = Object.keys(window[DSP_BRANDING].banners)

          if (banners.length > 0) {
            banners.forEach((key) => {
              window[DSP_BRANDING].banners[key].removeBanner()
            })
          }
        }
      }
    }
  })
}

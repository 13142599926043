import type {ApiCity} from 'types/cities'
import {Endpoints} from './request'
import {ApiService} from './service'

/**
 * Параметры для получения списка городов
 */
interface ListCitiesParams {
  geo_id?: number
  city_trans?: string
}

export class CitiesService extends ApiService {
  /**
   * Получение списка городов
   */
  listCities(query: ListCitiesParams): Promise<{items: ApiCity[]}> {
    return this.request<{items: ApiCity[]}>(Endpoints.LIST_CITIES, {query})
  }
}

import {ObservableMap, makeAutoObservable, observable} from 'mobx'

import {PremiereService} from 'common/api'

import type {Stores} from 'stores'
import type {ApiPremiereEvent, Premiere} from 'types/premiere'
import {getIconSrcset, getImageSrcset} from 'common/utils/srcset'

const PREMIERES_SINGLE_FETCH_COUNT = 4

export class PremiereStore {
  isFetching: boolean
  readonly stores: Stores
  readonly premiereService: PremiereService
  readonly weekPremieresMap: ObservableMap<
    string,
    {
      totalCount: number
      currentCount: number
      premieres: Premiere[]
    }
  >
  readonly weekendPremieresMap: ObservableMap<
    string,
    {
      totalCount: number
      currentCount: number
      premieres: Premiere[]
    }
  >

  constructor(stores: Stores) {
    this.isFetching = false
    this.stores = stores
    this.premiereService = new PremiereService(stores.apiParams)

    this.weekPremieresMap = observable.map()
    this.weekendPremieresMap = observable.map()

    makeAutoObservable(this, {stores: false})
  }

  public fetchInitialPremieres = async (params: {
    isWeekend: boolean
  }): Promise<void> => {
    const premieresFetched = params.isWeekend
      ? this.weekendPremieres.length
      : this.weekPremieres.length

    if (premieresFetched) {
      return
    }

    await this.fetchMorePremieres(params)
  }

  public fetchMorePremieres = async (params: {
    isWeekend: boolean
  }): Promise<void> => {
    if (this.isFetching) return

    this.isFetching = true

    const {city} = this.stores.routeParamsStore

    const {items: fetchedPremieres, count} =
      await this.premiereService.listPremiereEvents({
        weekend: params.isWeekend,
        city_trans: city.nameTranslit,
        start_from: params.isWeekend
          ? this.weekendPremieresCurrentCount
          : this.weekPremieresCurrentCount,
        limit: params.isWeekend
          ? this.weekendPremieresCurrentCount + PREMIERES_SINGLE_FETCH_COUNT
          : this.weekPremieresCurrentCount + PREMIERES_SINGLE_FETCH_COUNT
      })

    if (params.isWeekend) {
      this.weekendPremieresMap.set(this.key, {
        premieres: this.weekendPremieres.concat(
          fetchedPremieres.map(this.formatPremiere)
        ),
        totalCount: count,
        currentCount:
          this.weekendPremieresCurrentCount + fetchedPremieres.length
      })
    } else {
      this.weekPremieresMap.set(this.key, {
        premieres: this.weekPremieres.concat(
          fetchedPremieres.map(this.formatPremiere)
        ),
        totalCount: count,
        currentCount: this.weekPremieresCurrentCount + fetchedPremieres.length
      })
    }

    this.isFetching = false
  }

  private get key(): string {
    const {city} = this.stores.routeParamsStore

    return city.nameTranslit
  }

  public get weekPremieres(): Premiere[] {
    return this.weekPremieresMap.get(this.key)?.premieres ?? []
  }

  public get weekPremieresTotalCount(): number {
    return this.weekPremieresMap.get(this.key)?.totalCount ?? 0
  }

  private get weekPremieresCurrentCount(): number {
    return this.weekPremieresMap.get(this.key)?.currentCount ?? 0
  }

  public get weekendPremieres(): Premiere[] {
    return this.weekendPremieresMap.get(this.key)?.premieres ?? []
  }

  public get weekendPremieresTotalCount(): number {
    return this.weekendPremieresMap.get(this.key)?.totalCount ?? 0
  }

  private get weekendPremieresCurrentCount(): number {
    return this.weekendPremieresMap.get(this.key)?.currentCount ?? 0
  }

  private formatPremiere = (premiere: ApiPremiereEvent): Premiere => {
    const categoryName = premiere.category.map((cat) => cat.displayed_name)
    const genreNames = premiere.genre.map((genre) => genre.name)

    const tags = [...categoryName, ...genreNames].slice(0, 2)

    const iconSrcset = getIconSrcset(premiere.resized_icons)
    const imageSrcset = getImageSrcset(premiere.icon[0]?.resized_icons)

    return {
      eventId: premiere.event_id,
      titleId: premiere.title_id,
      title: premiere.title,
      titleTranslit: premiere.title_trans,
      channelTranslit: premiere.base_channel_display_name_trans,
      channelId: premiere.channel_id,
      series: premiere.is_serial && premiere.series ? premiere.series : [],
      imageSrc: premiere.icon[0]?.src ?? '',
      imageSrcset,
      description: premiere.description_short,
      start: new Date(premiere.start),
      tags,
      isSerial: premiere.is_serial,
      channel: {
        name: premiere.channel_display_name,
        nameTranslit: premiere.channel_display_name_trans,
        iconSrc: premiere.channel_icon,
        iconSrcset,
        baseChannelId: premiere.base_channel_id,
        id: premiere.channel_id
      }
    }
  }

  serialize = (): Pick<
    PremiereStore,
    'weekPremieresMap' | 'weekendPremieresMap'
  > => ({
    weekPremieresMap: this.weekPremieresMap,
    weekendPremieresMap: this.weekendPremieresMap
  })

  hydrate = ({
    weekPremieresMap,
    weekendPremieresMap
  }: ReturnType<PremiereStore['serialize']>): void => {
    this.weekPremieresMap.replace(weekPremieresMap)
    this.weekendPremieresMap.replace(weekendPremieresMap)
  }
}

export const YESTERDAY_SLUG = '/yesterday/'
export const TODAY_SLUG = '/today/'
export const TOMORROW_SLUG = '/tomorrow/'

export enum Urls {
  MAIN = '/',
  MOSKVA_TODAY = '/moskva/today/',

  CITY = '/:city/',
  DATE = '/:city/:date',
  YESTERDAY = `/:city${YESTERDAY_SLUG}`,
  TODAY = `/:city${TODAY_SLUG}`,
  TOMORROW = `/:city${TOMORROW_SLUG}`,
  CATEGORY = '/:city/category/:category/:date?',

  WEEK = '/:city/week/',
  WEEKEND = '/:city/weekend/',

  PERSONAL = '/personal/',

  CHANNEL = '/:city/channel/:channel/',

  CHANNEL_DATE = '/:city/channel/:channel/:date',

  SHOW = '/:city/:channel/:show/',

  EPISODE = '/:city/:channel/:show/*'
}

import {makeAutoObservable, type IObservableArray, observable} from 'mobx'

import {NewsService} from 'common/api'

import type {ApiNewsItem, NewsItem} from 'types/news'
import type {Stores} from 'stores'

export class NewsStore {
  isFetching: boolean
  readonly stores: Stores
  readonly newsService: NewsService
  readonly news: IObservableArray<NewsItem>
  readonly newsRegional: IObservableArray<NewsItem>

  constructor(stores: Stores) {
    this.isFetching = true
    this.stores = stores
    this.newsService = new NewsService()

    this.news = observable<NewsItem>([])
    this.newsRegional = observable<NewsItem>([])

    makeAutoObservable(this, {stores: false})
  }

  public fetchRecommendedNews = async ({
    isMobile,
    isRegional
  }: {
    isMobile: boolean
    isRegional: boolean
  }): Promise<void> => {
    const newsFetched = isRegional ? this.newsRegional.length : this.news.length

    if (newsFetched) {
      return
    }

    this.isFetching = true

    const {recommendations: fetchedNews} =
      await this.newsService.listRecommendedNews({isMobile, isRegional})

    if (isRegional) {
      this.newsRegional.replace(fetchedNews.map(this.formatNewsItem))
    } else {
      this.news.replace(fetchedNews.map(this.formatNewsItem))
    }

    this.isFetching = false
  }

  public setFetching(value: boolean): void {
    this.isFetching = value
  }

  public getNews({isRegional}: {isRegional: boolean}): NewsItem[] {
    return isRegional ? [...this.newsRegional] : [...this.news]
  }

  private formatNewsItem = (newsItem: ApiNewsItem): NewsItem => ({
    itemId: newsItem.item_id,
    title: newsItem.item_title,
    href: newsItem.item_url,
    date: new Date(newsItem.item_pubdate),
    image: newsItem.item_image,
    rcmId: newsItem.rcm_id,
    showId: newsItem.show_id
  })

  public serialize = (): Pick<NewsStore, 'news' | 'newsRegional'> => ({
    news: this.news,
    newsRegional: this.newsRegional
  })

  public hydrate = ({
    news,
    newsRegional
  }: ReturnType<NewsStore['serialize']>): void => {
    this.news.replace(news)
    this.newsRegional.replace(newsRegional)
  }
}

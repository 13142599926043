/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import {useViewport} from '@rambler-id/react'
import clsx from 'clsx'

import {DESKTOP_BREAKPOINT} from 'common/constants/viewport'
import {Blocks} from './blocks'

import styles from './styles.module.css'

export const ShowPage: React.FC = () => {
  const {isDesktop} = useViewport([DESKTOP_BREAKPOINT])

  return (
    <div>
      <div className={styles.billboard} />
      <div className={clsx(!isDesktop && styles.tablet)}>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.leftColumn}>
              <div className={styles.row}>
                <div
                  className={clsx(
                    styles.pageTitle,
                    styles.wide,
                    styles.animated,
                    styles.item
                  )}
                />
                <div
                  className={clsx(
                    styles.select,
                    styles.animated,
                    styles.channelPage,
                    styles.item
                  )}
                />
              </div>
              <div className={styles.show}>
                <div className={styles.showLeftColumn}>
                  <div className={clsx(styles.item, styles.image)} />
                  <div className={styles.votes}>
                    <Blocks count={2} />
                  </div>
                  <div className={styles.showCategories}>
                    <Blocks count={4} />
                  </div>
                </div>
                <div className={styles.showRightColumn}>
                  <div className={styles.text}>
                    <Blocks count={3} />
                  </div>
                  <div className={styles.showInfo}>
                    {new Array(6).fill('').map((_, index) => (
                      <div key={index} className={styles.showInfoRow}>
                        <Blocks count={2} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.showSchedule}>
                <div
                  className={clsx(
                    styles.animated,
                    styles.item,
                    styles.channelTitle
                  )}
                />
                {new Array(3).fill('').map((_, index) => (
                  <div key={index} className={styles.showScheduleRow}>
                    <Blocks count={3} />
                  </div>
                ))}
              </div>
              <div className={styles.selection}>
                <div
                  className={clsx(
                    styles.item,
                    styles.animated,
                    styles.pageTitle,
                    styles.wide
                  )}
                />
                <div className={styles.selectionList}>
                  {new Array(4).fill('').map((_, index) => (
                    <div key={index} className={styles.selectionItem}>
                      <div
                        className={clsx(
                          styles.image,
                          styles.item,
                          styles.animated
                        )}
                      />
                      <Blocks count={2} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {isDesktop && (
              <div className={styles.rightColumn}>
                <div
                  className={clsx(
                    styles.news,
                    styles.animated,
                    styles.noMargin
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

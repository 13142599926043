export const getImageSrcset = (resizedIcon: {
  [dimensions: string]: string
}): string | null => {
  let imageSrcset: string | null = null

  try {
    imageSrcset = Object.entries(resizedIcon)
      .map(([dimensions, src]) => `${src} ${dimensions.split('x')[0]}w`)
      .join(', ')
  } catch {}

  return imageSrcset
}

export const getIconSrcset = (resizedIcon: {
  [dimensions: string]: string
}): string | null => {
  let iconSrcset: string | null = null

  try {
    iconSrcset = Object.entries(resizedIcon)
      .map(
        ([dimensions, src]) => `${src} ${dimensions === '30x30' ? '1x' : '2x'}`
      )
      .join(', ')
  } catch {}

  return iconSrcset
}

import {ApiError} from '@rambler-id/errors'
import {
  NEWS_DESKTOP_BLOCK_ID,
  NEWS_MOBILE_BLOCK_ID,
  NEWS_REGIONAL_DESKTOP_BLOCK_ID,
  NEWS_REGIONAL_MOBILE_BLOCK_ID,
  RCM_NEWS_LIMIT
} from 'common/constants/rcm'
import {RCM_API_ORIGIN} from 'common/utils/env'

import type {ApiNewsItem} from 'types/news'
import {httpRequest, HttpMethods} from './request'

export class NewsService {
  /**
   * Получение списка новостей
   */
  async listRecommendedNews({
    isMobile,
    isRegional
  }: {
    isMobile: boolean
    isRegional: boolean
  }): Promise<{recommendations: ApiNewsItem[]}> {
    const requestOptions = {
      origin: RCM_API_ORIGIN,
      method: HttpMethods.GET,
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip'
      },
      query: {
        limit: RCM_NEWS_LIMIT.toString()
      }
    }

    let json

    try {
      let blockId

      if (isRegional) {
        blockId = isMobile
          ? NEWS_REGIONAL_MOBILE_BLOCK_ID
          : NEWS_REGIONAL_DESKTOP_BLOCK_ID
      } else {
        blockId = isMobile ? NEWS_MOBILE_BLOCK_ID : NEWS_DESKTOP_BLOCK_ID
      }

      const response = await httpRequest(
        `/api/v2/blocks/${blockId}/recommendations`,
        requestOptions
      )

      json = (await response.json()) as {recommendations: ApiNewsItem[]}
    } catch {
      throw new ApiError({
        code: 0,
        message: 'api does not respond',
        details: {
          headers: requestOptions.headers
        }
      })
    }

    return json
  }
}

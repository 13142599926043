export interface TimeFilterParams {
  label: string
  value: TimeFilterKey
  startHour: number
  endHour: number
}

export enum TimeFilterKey {
  NOW = 'now',
  ALL_DAY = 'all_day',
  MORNING = 'morning',
  MIDDAY = 'midday',
  EVENING = 'evening',
  NIGHT = 'night'
}

export const TIME_FILTERS: TimeFilterParams[] = [
  {
    label: 'Сейчас',
    value: TimeFilterKey.NOW,
    startHour: 0,
    endHour: 24
  },
  {
    label: 'Весь день',
    value: TimeFilterKey.ALL_DAY,
    startHour: 0,
    endHour: 24
  },
  {
    label: 'Утро',
    value: TimeFilterKey.MORNING,
    startHour: 5,
    endHour: 12
  },
  {
    label: 'День',
    value: TimeFilterKey.MIDDAY,
    startHour: 12,
    endHour: 18
  },
  {
    label: 'Вечер',
    value: TimeFilterKey.EVENING,
    startHour: 18,
    endHour: 24
  },
  {
    label: 'Ночь',
    value: TimeFilterKey.NIGHT,
    startHour: 0,
    endHour: 5
  }
]

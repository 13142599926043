import {makeAutoObservable, type IObservableArray, observable} from 'mobx'

import {CategoryService} from 'common/api'

import type {ChannelCategory, ApiChannelCategory} from 'types/category'
import type {Stores} from 'stores'

export class CategoryStore {
  isFetching: boolean
  readonly stores: Stores
  readonly categoryService: CategoryService
  readonly categories: IObservableArray<ChannelCategory>

  constructor(stores: Stores) {
    this.stores = stores
    this.categoryService = new CategoryService(stores.apiParams)

    this.isFetching = true
    this.categories = observable<ChannelCategory>([])

    makeAutoObservable(this, {stores: false})
  }

  public fetchChannelCategories = async (): Promise<void> => {
    const categoriesFetched = !!this.categories.length

    if (categoriesFetched) {
      return
    }

    this.isFetching = true

    const {items: categories} =
      await this.categoryService.listManualChannelCategories()

    this.categories.replace(categories.map(this.formatCategory))
    this.isFetching = false
  }

  public get category(): ChannelCategory | null {
    const {category: categoryNameTranslit} = this.stores.routeParamsStore

    return (
      this.categories.find(
        ({nameTranslit}) => nameTranslit === categoryNameTranslit
      ) ?? null
    )
  }

  private formatCategory = (category: ApiChannelCategory): ChannelCategory => ({
    id: category.manual_category_id,
    name: category.manual_category_name,
    nameTranslit: category.manual_category_name_trans,
    seoTitle: category.metatag_title,
    seoDescription: category.metatag_description,
    seoH1: category.metatag_h1,
    seoH2: category.metatag_h2,
    seoText: category.metatag_text
  })

  public serialize = (): Pick<CategoryStore, 'categories'> => ({
    categories: this.categories
  })

  public hydrate = ({
    categories
  }: ReturnType<CategoryStore['serialize']>): void => {
    this.categories.replace(categories)
  }
}

import type {ApiEventCategory, ApiEvent, ApiEventInList} from 'types/show'
import type {VoteTitleType} from 'types/vote-type'
import {Endpoints} from './request'
import {ApiService} from './service'

/**
 * Параметры для получения подробной информации о передаче
 */
interface GetEventRawParams {
  event_id: string
}

/**
 * Параметры для получения расписания передач
 * Для выбора периода необходимо указать один из параметров:
 * week / date / time_from time_to
 */
interface ListEventsParams {
  channel_id?: number | string
  category_id?: number
  genre_id?: number
  title_id?: number | string
  time_from?: string
  time_to?: string
  week?: string
  date?: string
  title?: string
  user_id?: string
  is_favorited?: boolean
  is_bookmarked?: boolean
  group_by_title?: boolean
  start_from: number
  limit: number
  city_trans: string
}

interface ListFavoriteEventsParams {
  start_from: number
  limit: number
  city_trans: string
}

/**
 * Параметры для добавления тайтла в избранное
 */
interface FavoriteTitleParams {
  title_id: number
}

/**
 * Параметры для удаления передачи из избранного
 */
interface RemoveFavoriteTitleParams {
  title_id: number
}

/**
 * Параметры для получения подробной информации о передаче
 */
interface GetTitleParams {
  channel_id: string
  title_id: string
  city_trans: string
}

export interface VoteTitleParams {
  title_id: number
  vote: VoteTitleType
}

type VoteTitleResponse = Pick<
  ApiEvent['event'],
  'like_count' | 'dislike_count' | 'title_id'
>

export class EventService extends ApiService {
  /**
   * Получение категорий передач
   */
  listEventCategories(): Promise<ApiEventCategory[]> {
    return this.request<ApiEventCategory[]>(Endpoints.LIST_EVENTS_CATEGORIES)
  }

  /**
   * Получение подробной информации о передаче
   */
  getEventRaw(query: GetEventRawParams): Promise<ApiEvent[]> {
    return this.request<ApiEvent[]>(Endpoints.GET_EVENT_RAW, {query})
  }

  /**
   * Получение расписания передач
   */
  listEvents(
    query: ListEventsParams
  ): Promise<{items: ApiEventInList[]; count: number}> {
    return this.request<{items: ApiEventInList[]; count: number}>(
      Endpoints.LIST_EVENTS,
      {query}
    )
  }

  /**
   * Получение избранных передач для страницы избранного
   */
  listFavoriteTitles(
    query: ListFavoriteEventsParams
  ): Promise<{items: ApiEventInList[]; count: number}> {
    return this.request<{items: ApiEventInList[]; count: number}>(
      Endpoints.LIST_EVENTS,
      {
        query: {
          is_favorited: true,
          group_by_title: true,
          ...query
        },
        withSessionToken: true
      }
    )
  }

  /**
   * Добавление тайтла в избранное
   */
  favoriteTitle(query: FavoriteTitleParams): Promise<ApiEvent> {
    return this.request<ApiEvent>(Endpoints.FAVORITE_TITLE, {
      query,
      withSessionToken: true
    })
  }

  /**
   * Удаление тайтла из избранного
   */
  removeFavoriteTitle(query: RemoveFavoriteTitleParams): Promise<ApiEvent> {
    return this.request<ApiEvent>(Endpoints.REMOVE_FAVORITE_TITLE, {
      query,
      withSessionToken: true
    })
  }

  /**
   * * Получение списка идентификаторов всех избранных тайтлов пользователя
   */
  listInteractedTitleIds(): Promise<{
    liked: number[]
    disliked: number[]
    favorited: number[]
  }> {
    return this.request<{
      liked: number[]
      disliked: number[]
      favorited: number[]
    }>(Endpoints.LIST_INTERACTED_TITLE_IDS, {
      withSessionToken: true
    })
  }

  /**
   * Получение подробной информации о передаче
   */
  getTitle(query: GetTitleParams): Promise<ApiEvent> {
    return this.request<ApiEvent>(Endpoints.GET_TITLE, {query})
  }

  /**
   * Получение подробной информации о передаче
   */
  voteTitle(query: VoteTitleParams): Promise<VoteTitleResponse> {
    return this.request<VoteTitleResponse>(Endpoints.VOTE_TITLE, {
      query,
      withSessionToken: true
    })
  }
}

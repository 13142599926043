import * as cookies from '@rambler-id/cookies'

const VIEWPORT_COOKIE_NAME = 'rswitch'
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const VIEWPORT_COOKIE_EXPIRES = 3 * 24 * 60 * 60 * 1000 // 3 days

export const changeViewport = ({isMobile}: {isMobile: boolean}): void => {
  const value = isMobile ? 'mobile' : 'desktop'

  cookies.setItem(VIEWPORT_COOKIE_NAME, value, {
    domain: '.rambler.ru',
    path: '/',
    expires: VIEWPORT_COOKIE_EXPIRES,
    raw: true
  })

  window.location.reload()
}

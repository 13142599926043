import * as SentryReact from '@sentry/react'
import {CaptureConsole} from '@sentry/integrations'
import {
  NODE_ENV,
  SENTRY_DSN,
  SENTRY_SAMPLE_RATE,
  SENTRY_TRACES_SAMPLE_RATE,
  VERSION
} from 'common/utils/env'

interface InitSentryOptions {
  pathname: string
  deviceType: string
}

export const initSentry = (options: InitSentryOptions): void => {
  if (NODE_ENV === 'development') {
    return
  }

  SentryReact.init({
    dsn: SENTRY_DSN,
    environment: NODE_ENV,
    release: VERSION,
    sampleRate: Number(SENTRY_SAMPLE_RATE),
    tracesSampleRate: Number(SENTRY_TRACES_SAMPLE_RATE),
    integrations: [
      new CaptureConsole({
        /* NOTE: defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert'] */
        levels: ['warn', 'error']
      }),
      new SentryReact.BrowserTracing()
    ]
  })

  SentryReact.configureScope((scope) => {
    scope.setTag('release', VERSION)
    scope.setTag('pathname', options.pathname)
    scope.setTag('deviceType', options.deviceType)
  })
}

export const captureException = (
  error: Error,
  extraTags?: Record<string, string>
): void => {
  if (NODE_ENV === 'development') {
    return
  }

  SentryReact.captureException(error, (scope) => {
    if (extraTags) {
      const tagEntries = Object.entries(extraTags)

      tagEntries.forEach((tag) => {
        scope.setTag(...tag)
      })
    }

    return scope
  })
}

import type {ApiRequestParams} from 'common/api/request'
import {apiRequest} from './request'

export class ApiService {
  readonly params: Partial<ApiRequestParams>

  constructor(params: Partial<ApiRequestParams> = {}) {
    this.params = params
  }

  request<T = any>(path: string, params: ApiRequestParams = {}) {
    return apiRequest<T>(path, {...this.params, ...params})
  }
}

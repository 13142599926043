/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import {useViewport} from '@rambler-id/react'
import clsx from 'clsx'

import {DESKTOP_BREAKPOINT} from 'common/constants/viewport'
import {Blocks} from './blocks'

import styles from './styles.module.css'

export const FavoritePage: React.FC = () => {
  const {isDesktop} = useViewport([DESKTOP_BREAKPOINT])

  return (
    <div>
      <div className={clsx(!isDesktop && styles.tablet)}>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.leftColumn}>
              <div
                className={clsx(
                  styles.pageTitle,
                  styles.short,
                  styles.animated,
                  styles.item
                )}
              />
              <div className={styles.favoriteList}>
                {new Array(isDesktop ? 3 : 2).fill('').map((_, index) => (
                  <div key={index} className={styles.favoriteItem}>
                    <div
                      className={clsx(
                        styles.favoriteItemTitle,
                        styles.animated,
                        styles.item
                      )}
                    />
                    <div
                      className={clsx(
                        styles.premiereImage,
                        styles.animated,
                        styles.item
                      )}
                    />
                    <div
                      className={clsx(
                        styles.animated,
                        styles.item,
                        styles.favoriteTitle
                      )}
                    />
                    <div className={styles.favoriteSchedule}>
                      <Blocks count={6} />
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.row}>
                <div
                  className={clsx(
                    styles.item,
                    styles.animated,
                    styles.pageTitle
                  )}
                />
                <div
                  className={clsx(styles.item, styles.animated, styles.select)}
                />
              </div>
              <div
                className={clsx(
                  styles.item,
                  styles.animated,
                  styles.navigation
                )}
              />
              <div className={styles.categories}>
                <Blocks count={4} />
              </div>
              <div className={clsx(styles.favoriteList, styles.last)}>
                {new Array(isDesktop ? 3 : 2).fill('').map((_, index) => (
                  <div key={index} className={styles.favoriteItem}>
                    <div
                      className={clsx(
                        styles.favoriteItemTitle,
                        styles.animated,
                        styles.item
                      )}
                    />
                    {isDesktop && (
                      <>
                        <div
                          className={clsx(
                            styles.premiereImage,
                            styles.animated,
                            styles.item
                          )}
                        />
                        <div
                          className={clsx(
                            styles.animated,
                            styles.item,
                            styles.favoriteTitle
                          )}
                        />
                      </>
                    )}
                    <div className={styles.favoriteSchedule}>
                      <Blocks count={isDesktop ? 5 : 6} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.rightColumn}>
              {!isDesktop && (
                <div className={clsx(styles.registration, styles.animated)} />
              )}
              <div
                className={clsx(
                  styles.news,
                  styles.animated,
                  isDesktop && styles.noMargin
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import type {ApiChannel} from 'types/channel'
import {Endpoints} from './request'
import {ApiService} from './service'

/**
 * Параметры для получения списка каналов
 */
interface ListChannelsParams {
  city_trans?: string
  category_id?: string
  channel_id?: string
  manual_category_name_trans?: string
  is_favorited?: boolean
  start_from?: number
  limit?: number
  display_name_trans?: string
  geo_id?: string
}

interface ListFavoriteChannelsParams {
  start_from: number
  limit: number
}

/**
 * Параметры для добавления канала в избранное
 */
interface FavoriteChannelParams {
  channel_id: number
}

/**
 * Параметры для удаления канала из избранного
 */
interface RemoveFavoriteChannelParams {
  channel_id: number
}

export class ChannelService extends ApiService {
  /**
   * Получение списка каналов
   */
  listChannels(
    query: ListChannelsParams
  ): Promise<{items: ApiChannel[]; count: number}> {
    return this.request<{items: ApiChannel[]; count: number}>(
      Endpoints.LIST_CHANNELS,
      {
        query
      }
    )
  }

  /**
   * Получение списка избранных каналов для страницы избранного
   */
  listFavoriteChannels(
    query: ListFavoriteChannelsParams
  ): Promise<{items: ApiChannel[]; count: number}> {
    return this.request<{items: ApiChannel[]; count: number}>(
      Endpoints.LIST_CHANNELS,
      {
        query: {is_favorited: true, ...query},
        withSessionToken: true
      }
    )
  }

  /**
   * Добавление канала в избранное
   */
  favoriteChannel(query: FavoriteChannelParams): Promise<ApiChannel> {
    return this.request<ApiChannel>(Endpoints.FAVORITE_CHANNEL, {
      query,
      withSessionToken: true
    })
  }

  /**
   * Удаление канала из избранного
   */
  removeFavoriteChannel(
    query: RemoveFavoriteChannelParams
  ): Promise<ApiChannel> {
    return this.request<ApiChannel>(Endpoints.REMOVE_FAVORITE_CHANNEL, {
      query,
      withSessionToken: true
    })
  }

  /**
   * Получение списка идентификаторов всех избранных каналов пользователя
   */
  listFavoriteChannelIds(): Promise<{items: number[]}> {
    return this.request<{items: number[]}>(
      Endpoints.LIST_FAVORITE_CHANNEL_IDS,
      {
        withSessionToken: true
      }
    )
  }
}

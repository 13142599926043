import {loadScript} from '@rambler-id/dom'
import {RECOMMENDER_SDK_SCRIPT, RECOMMENDER_ID} from 'common/constants/rcm'

let recommenderInstancePromise: Promise<void>
let isRCMInitialized = false

interface RCMEvent {
  blockId: string
  position: number
  item: {
    item_id: string
    rcm_id: string
    show_id: null | string
    item_url?: string
  }
}

const safeRCM = (callback: () => void): void => {
  if (window.rcm && isRCMInitialized) {
    callback()
  } else {
    window.addEventListener('recommender-init', () => {
      callback()
    })
  }
}

export const rcmPageView = (itemId: string): void =>
  safeRCM(() => {
    window.rcm('pageview', {itemId})
  })

export const rcmBlockInit = (blockId: string): void =>
  safeRCM(() => {
    window.rcm('blockinit', {blockId})
  })

export const rcmShow = (event: RCMEvent): void =>
  safeRCM(() => {
    window.rcm('show', event)
  })

export const rcmClick = (event: RCMEvent): void =>
  safeRCM(() => {
    window.rcm('click', event)
  })

export const setupRCM = (xuid: string | null): void => {
  safeRCM(() =>
    window.rcm('setup', {
      rcmId: RECOMMENDER_ID,
      xuid
    })
  )
}

export const initRecommender = async (): Promise<void> => {
  recommenderInstancePromise ??= (async () => {
    try {
      window.rcm ??= (args: any) => {
        ;(window.rcm.q = window.rcm.q || []).push(args)
      }

      await loadScript(RECOMMENDER_SDK_SCRIPT)
      isRCMInitialized = true
      window.dispatchEvent(new CustomEvent('recommender-init'))
    } catch {}
  })()

  await recommenderInstancePromise
}

/**
 * NOTE: move enum to separate file due to ts-jest error
 * enum VoteTitleType is used on ShowPage which is snapshoted tests
 *
 * https://github.com/kulshekhar/ts-jest/issues/281
 */

/**
 * Параметры для проставления лайка/дизлайка для тайтла (передачи)
 */
export const enum VoteTitleType {
  LIKE = 'Like',
  DISLIKE = 'Dislike'
}

import React from 'react'
import {
  Meta,
  Preloads,
  Styles,
  State,
  Scripts
} from '@rambler-tech/react-toolkit/client'
import {CapirsLoaderComponent} from '@rambler-components/ad'

import {branderScript} from 'common/utils/brander'

/** Default document props */
interface DocumentProps {
  children: React.ReactNode
}

/** Default document component */
export const DocumentCommon: React.FC<DocumentProps> = ({children}) => (
  <html lang="ru">
    <head>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <Meta />
      <Preloads />
      <Styles />

      <CapirsLoaderComponent resetSyncBannersStateOnHistoryChange={false} />
      <script dangerouslySetInnerHTML={{__html: `(${branderScript})()`}} />
    </head>

    <body className="ad_branding_site">
      {children}
      <State />
      <Scripts />
    </body>
  </html>
)

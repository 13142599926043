import {getRamblerIdHelper} from '@rambler-id/helper'
import {ApiError} from '@rambler-id/errors'
import {TV_API_ORIGIN, TV_API_PREFIX} from 'common/utils/env'
import {Endpoints, HttpMethods, httpRequest} from './request'
import {ApiService} from './service'

/**
 * Параметры для выполнения блочного запроса
 */
interface BulkGetParams {
  requests: {
    method: string
    params: Record<string, string | number>
  }[]
}

export class BulkService extends ApiService {
  /**
   * Блочно выполняет запросы
   */
  async bulkGet<T>({requests}: BulkGetParams): Promise<{answers: T[]}> {
    const {requestId} = this.params
    let rsidx: string | undefined

    try {
      const ramblerIdHelper = await getRamblerIdHelper()

      rsidx = await new Promise((resolve) =>
        ramblerIdHelper?.getSessionToken((response) => {
          resolve(response?.token)
        })
      )
    } catch {}

    const requestOptions = {
      origin: `${TV_API_ORIGIN}${TV_API_PREFIX}`,
      method: HttpMethods.POST,
      headers: {
        'Content-Type': 'application/json',
        ...(requestId && {'X-Request-Id': requestId})
      },
      body: {
        session_token: rsidx,
        requests
      }
    }

    let json

    let response

    try {
      response = await httpRequest(Endpoints.BULK_GET, requestOptions)

      json = await response.json()
    } catch {
      throw new ApiError({
        code: 0,
        message: 'api does not respond',
        details: {
          headers: requestOptions.headers
        }
      })
    }

    if (json && typeof json === 'object' && 'error' in json) {
      throw new ApiError({
        message: json.error,
        details: {
          headers: requestOptions.headers
        }
      })
    }

    return json
  }
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import {useViewport} from '@rambler-id/react'
import clsx from 'clsx'

import {DESKTOP_BREAKPOINT} from 'common/constants/viewport'
import {Blocks} from './blocks'

import styles from './styles.module.css'

export const PremierePage: React.FC = () => {
  const {isDesktop} = useViewport([DESKTOP_BREAKPOINT])

  return (
    <div>
      <div className={clsx(!isDesktop && styles.tablet)}>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={clsx(styles.leftColumn, styles.premierePage)}>
              <div
                className={clsx(
                  styles.pageTitle,
                  styles.wide,
                  styles.animated,
                  styles.item
                )}
              />
              <div className={styles.premieres}>
                {new Array(4).fill('').map((_, index) => (
                  <div key={index} className={styles.premiere}>
                    <div
                      className={clsx(
                        styles.animated,
                        styles.item,
                        styles.premiereImage
                      )}
                    />
                    <div className={styles.premiereInfo}>
                      <div className={styles.premiereRow}>
                        <Blocks count={3} />
                      </div>
                      <div className={styles.premiereDescription}>
                        <Blocks count={isDesktop ? 3 : 4} />
                      </div>
                      <div className={styles.premiereTime}>
                        <Blocks count={3} />
                      </div>
                    </div>
                  </div>
                ))}
                {isDesktop && (
                  <div className={styles.button}>
                    <div className={clsx(styles.item, styles.animated)} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={clsx(styles.registration, styles.animated)} />
              <div className={clsx(styles.news, styles.animated)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

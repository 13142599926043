import {ApiError} from '@rambler-id/errors'
import {getRamblerIdHelper} from '@rambler-id/helper'
import {TV_API_ORIGIN, TV_API_PREFIX} from 'common/utils/env'

type ApiResponse<T> =
  | T
  | {
      error: string
    }

export interface ApiRequestParams {
  query?: Record<string, any>
  requestId?: string
  withSessionToken?: boolean
}

/**
 * Запрос в API ТВ-программы
 */
export const apiRequest = async <T>(
  path: string,
  {query = {}, withSessionToken, requestId}: ApiRequestParams = {}
): Promise<T> => {
  const data = {...query}
  let rsidx: string | undefined

  if (withSessionToken) {
    try {
      const ramblerIdHelper = await getRamblerIdHelper()

      rsidx = await new Promise((resolve) => {
        ramblerIdHelper?.getSessionToken((response) => {
          resolve(response?.token)
        })
      })
    } catch {}
  }

  const requestOptions = {
    origin: `${TV_API_ORIGIN}${TV_API_PREFIX}`,
    method: HttpMethods.GET,
    headers: {
      'Content-Type': 'application/json',
      ...(requestId && {'X-Request-Id': requestId})
    },
    query: {
      ...data,
      ...(rsidx && {session_token: rsidx})
    }
  }

  let json
  let response

  try {
    response = await httpRequest(path, requestOptions)

    json = (await response.json()) as ApiResponse<T>
  } catch (error: any) {
    throw new ApiError({
      code: 0,
      message: 'api does not respond',
      details: {
        path,
        requestOptions,
        error,
        requestId
      }
    })
  }

  if (json && typeof json === 'object' && 'error' in json) {
    throw new ApiError({
      message: json.error,
      details: {
        path,
        headers: requestOptions.headers,
        requestId
      }
    })
  }

  return json
}

export enum HttpMethods {
  GET = 'get',
  POST = 'post'
}

/**
 * HTTP-запрос с помощью глобального fetch
 */
interface RequestOptions {
  origin?: string
  method?: HttpMethods
  headers?: Record<string, string>
  query?: Record<string, string>
  body?: Record<string, any> | string
}

export const httpRequest = (
  path: string,
  options: RequestOptions = {}
): Promise<Response> => {
  const {query, origin, body, ...restOptions} = options

  const requestOptions: RequestInit = {
    credentials: 'include',
    ...restOptions
  }

  if (body) {
    requestOptions.body =
      body instanceof FormData || typeof body === 'string'
        ? body
        : JSON.stringify(body)
  }

  let queryString = ''

  if (query) {
    queryString = new URLSearchParams(query).toString()
    queryString = queryString && `?${queryString}`
  }

  return fetch(`${origin}${path}${queryString}`, requestOptions)
}

export enum Endpoints {
  LIST_MANUAL_CHANNEL_CATEGORIES = '/list_manual_channel_categories',
  LIST_CHANNELS = '/list_channels',
  FAVORITE_CHANNEL = '/favorite_channel',
  REMOVE_FAVORITE_CHANNEL = '/remove_favorite_channel',
  LIST_EVENTS_CATEGORIES = 'list_event_categories',
  GET_EVENT_RAW = '/get_event_raw',
  LIST_EVENTS = '/list_events',
  LIST_PREMIERE_EVENTS = '/list_premiere_events',
  BOOKMARK_EVENT = '/bookmark_event',
  REMOVE_BOOKMARK_EVENT = '/remove_bookmark_event',
  LIST_META_TAGS = '/list_metatags',
  LIST_CITIES = '/list_cities',
  LIST_FAVORITE_CHANNEL_IDS = '/list_favorite_channel_ids',
  LIST_INTERACTED_TITLE_IDS = '/list_interacted_title_ids',
  FAVORITE_TITLE = '/favorite_title',
  REMOVE_FAVORITE_TITLE = '/remove_favorite_title',
  GET_TITLE = '/get_title',
  VOTE_TITLE = '/vote_title',
  GET_EPISODE = '/get_episode',
  BULK_GET = '/bulk_get',
  GET_AD_SETTINGS = '/get_ad_settings'
}

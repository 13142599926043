import {type IObservableArray, makeAutoObservable, observable} from 'mobx'

import {addWeeks, compareAsc, format} from 'date-fns'
import {ApiError} from '@rambler-id/errors'
import {EventService, EpisodeService} from 'common/api'
import {NOT_FOUND_ERROR} from 'common/constants/errors'
import type {VoteTitleParams} from 'common/api/event-service'
import {getIconSrcset, getImageSrcset} from 'common/utils/srcset'

import type {Show, ApiEventInList, ShowForPage, ApiEvent} from 'types/show'
import type {ApiEpisode, Episode} from 'types/episode'
import type {Stores} from 'stores'
import {VoteTitleType} from 'types/vote-type'

const NUMBER_OF_RECOMMENDED_SHOWS = 10

export class ShowStore {
  isFetching: boolean
  readonly stores: Stores
  readonly eventService: EventService
  readonly episodeService: EpisodeService
  shows: Record<string, Show[]>
  readonly showsForPage: IObservableArray<ShowForPage>
  readonly recommendedShowsForPage: IObservableArray<Show>
  readonly showEpisodes: IObservableArray<Episode>

  constructor(stores: Stores) {
    this.isFetching = true
    this.stores = stores
    this.eventService = new EventService(stores.apiParams)
    this.episodeService = new EpisodeService(stores.apiParams)

    this.shows = observable.object({})
    this.showsForPage = observable<ShowForPage>([])
    this.recommendedShowsForPage = observable<Show>([])
    this.showEpisodes = observable<Episode>([])

    makeAutoObservable(this, {stores: false})
  }

  public fetchShowsByChannelIds = async ({
    channelIds,
    date,
    shouldReplace
  }: {
    channelIds: number[]
    date: Date
    shouldReplace?: boolean
  }): Promise<void> => {
    this.isFetching = true

    const {city} = this.stores.routeParamsStore

    const dateKey = format(date, 'yyyy-MM-dd')

    const {items: fetchedShows} = await this.eventService.listEvents({
      channel_id: channelIds.join(','),
      date: dateKey,
      start_from: 0,
      limit: 3000,
      city_trans: city.nameTranslit
    })

    if (shouldReplace) {
      this.shows[dateKey] = fetchedShows.map(this.formatShowForSchedule)
    } else {
      const currentShows = this.shows[dateKey] ?? []

      const newFetchedShows = fetchedShows
        .filter(
          ({event_id}) =>
            ![...new Set(currentShows.map(({id}) => id))].includes(event_id)
        )
        .map(this.formatShowForSchedule)

      this.shows[dateKey] = currentShows.concat(newFetchedShows)
    }

    this.isFetching = false
  }

  public fetchShowsForPage = async (): Promise<void> => {
    const {show, city} = this.stores.routeParamsStore
    const showFetched = this.showForPage

    if (showFetched) return

    const fetchedShow = await this.eventService.getTitle({
      channel_id: String(show.channelId),
      title_id: String(show.titleId),
      city_trans: city.nameTranslit
    })

    if (!fetchedShow.event) {
      throw new Error(NOT_FOUND_ERROR)
    }

    this.showsForPage.push(this.formatShowForPage(fetchedShow))

    const recommendedShows = this.recommendedShows

    if (recommendedShows.length < NUMBER_OF_RECOMMENDED_SHOWS) {
      const {city} = this.stores.routeParamsStore
      const [category] = fetchedShow.event.category

      const {items: fetchedRecommendedShows} =
        await this.eventService.listEvents({
          time_from: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
          time_to: format(addWeeks(new Date(), 2), 'y-MM-dd'),
          start_from: 0,
          limit: 20,
          group_by_title: true,
          ...(category && {category_id: category.id}),
          city_trans: city.nameTranslit
        })

      this.recommendedShowsForPage.replace(
        this.recommendedShowsForPage.concat(
          fetchedRecommendedShows.map(this.formatShowForSchedule)
        )
      )
    }
  }

  public fetchEpisode = async (): Promise<void> => {
    const {episode: episodeId, city} = this.stores.routeParamsStore

    if (episodeId && !this.episodeForPopup) {
      let fetchedEpisode

      try {
        fetchedEpisode = await this.episodeService.getEpisode({
          event_id: episodeId,
          city_trans: city.nameTranslit
        })
      } catch (error) {
        if (
          error instanceof ApiError &&
          error.message === 'no items matching given query found'
        )
          throw new Error(NOT_FOUND_ERROR)
      }

      if (!fetchedEpisode) {
        throw new Error(NOT_FOUND_ERROR)
      }

      if (!this.showEpisodes.some(({id}) => id === episodeId)) {
        this.showEpisodes.push(this.formatEpisode(fetchedEpisode))
      }
    }
  }

  public handleShowVoteChange = async (
    vote: VoteTitleType | null
  ): Promise<void> => {
    if (!this.showForPage) return

    const params = {
      title_id: this.showForPage.titleId
    } as VoteTitleParams

    if (vote) {
      params.vote = vote
    }

    const {
      title_id: titleId,
      like_count: likeCount,
      dislike_count: dislikeCount
    } = await this.eventService.voteTitle(params)

    this.showsForPage.replace(
      this.showsForPage.map((show) =>
        show.titleId === titleId
          ? {
              ...show,
              likeCount,
              dislikeCount
            }
          : show
      )
    )

    if (!vote) {
      this.stores.favoriteStore.deleteLikedShowId(titleId)
      this.stores.favoriteStore.deleteDislikedShowId(titleId)
    }

    if (vote === VoteTitleType.LIKE) {
      this.stores.favoriteStore.addLikedShowId(titleId)
      this.stores.favoriteStore.deleteDislikedShowId(titleId)
    }

    if (vote === VoteTitleType.DISLIKE) {
      this.stores.favoriteStore.addDislikedShowId(titleId)
      this.stores.favoriteStore.deleteLikedShowId(titleId)
    }
  }

  public get episodeForPopup(): Episode | null {
    const {episode: episodeId} = this.stores.routeParamsStore

    return this.showEpisodes.find(({id}) => id === episodeId) ?? null
  }

  public get showForPage(): ShowForPage | null {
    const {show} = this.stores.routeParamsStore

    return (
      this.showsForPage.find(({titleId}) => titleId === show.titleId) ?? null
    )
  }

  public get recommendedShows(): Show[] {
    const uniqueShowsMap = new Map<number, Show>(
      this.recommendedShowsForPage.map((show) => [show.titleId, show])
    )

    const uniqueShowsArr = Array.from(uniqueShowsMap.values())

    return uniqueShowsArr
      .filter(
        ({titleId, categoryId}) =>
          titleId !== this.showForPage?.titleId &&
          categoryId === this.showForPage?.categoryId
      )
      .sort((a, b) => compareAsc(a.start, b.start))
  }

  public get showsForSchedule(): Show[] {
    const {date} = this.stores.routeParamsStore
    const dateKey = format(date, 'yyyy-MM-dd')

    return this.shows[dateKey] ?? []
  }

  public formatShowForSchedule = (event: ApiEventInList): Show => {
    const channelIconSrcset = getIconSrcset(event.resized_icons)
    const imageSrcset = getImageSrcset(event.icon[0]?.resized_icons)

    return {
      id: event.event_id,
      baseChannelId: event.base_channel_id,
      title: event.title,
      titleId: event.title_id,
      titleTranslit: event.title_trans,
      channelId: event.channel_id,
      channelNameTranslit: event.channel_display_name_trans,
      channelDisplayName: event.channel_display_name,
      channelIconSrc: event.channel_icon,
      channelIconSrcset,
      start: new Date(event.start),
      stop: new Date(event.stop),
      imageSrc: event.icon[0]?.src ?? '',
      imageSrcset,
      description: '',
      categoryId: event.category[0]?.id,
      live: false,
      isSerial: event.is_serial,
      season: event.season ?? null,
      series: event.series ?? null,
      kinopoiskRating: event.kinopoisk_rating,
      imdbRating: event.imdb_rating
    }
  }

  private formatEpisode = ({event, series}: ApiEpisode): Episode => ({
    id: event.event_id,
    start: new Date(event.start),
    stop: new Date(event.stop),
    title: event.title,
    subTitle: event.sub_title,
    description: event.sub_title_desc ?? event.description ?? '',
    images: event.icon
      .map(({src, resized_icons}) => ({
        src: src as string,
        srcset: getImageSrcset(resized_icons)
      }))
      .filter(({src}) => !!src),
    isPremiere: Boolean(event.is_premiere),
    tags: [
      ...event.category.map((cat) => cat.displayed_name),
      ...event.genre.map((genre) => genre.name)
    ].slice(0, 2),
    ageLimit: event.pg,
    season: event.season,
    series: event.series,
    schedule: series.reduce(
      (prev, curr) => ({...prev, [curr.event_id]: new Date(curr.start)}),
      {}
    ),
    category: event.category[0]
  })

  private formatShowForPage = ({
    event,
    series,
    comment_count: commentCount
  }: ApiEvent): ShowForPage => {
    const {country, credits, production} = event

    const getCreators = (): Record<string, string[]> => {
      const creators = {} as Record<string, string[]>

      if (credits.length) {
        credits.forEach(({type, name}) => {
          if (creators[type]) {
            creators[type].push(name)
          } else {
            creators[type] = []
            creators[type].push(name)
          }
        })
      }

      if (country.length) {
        creators.country = event.country.map(({name}) => name)
      }

      if (production.length) {
        creators.production = event.production.map(({name}) => name)
      }

      return creators
    }

    const imageSrcset = getImageSrcset(event.icon[0]?.resized_icons)

    return {
      id: event.event_id,
      channelId: event.channel_id,
      channelNameTranslit: event.channel_display_name_trans,
      channelDisplayName: event.channel_display_name,
      title: event.title,
      titleId: event.title_id,
      titleTranslit: event.title_trans,
      start: new Date(event.start),
      stop: new Date(event.stop),
      imageSrc: event.icon[0]?.src ?? '',
      imageSrcset,
      tags: [
        ...event.category.map((cat) => cat.displayed_name),
        ...event.genre.map((genre) => genre.name)
      ].slice(0, 2),
      description: event.description ?? '',
      creators: getCreators(),
      series: series ?? [],
      period: event.year?.split('-').map((year) => new Date(year)) ?? null,
      ageLimit: event.pg,
      isSerial: event.is_serial,
      isFavorite: event.is_favorited,
      isPremiere: Boolean(event.is_premiere),
      likeCount: event.like_count,
      dislikeCount: event.dislike_count,
      categoryId: event.category[0]?.id,
      commentCount,
      imdbRating: event.imdb_rating,
      kinopoiskRating: event.kinopoisk_rating
    }
  }

  serialize = (): Pick<
    ShowStore,
    'shows' | 'showsForPage' | 'recommendedShowsForPage' | 'showEpisodes'
  > => ({
    shows: this.shows,
    showsForPage: this.showsForPage,
    recommendedShowsForPage: this.recommendedShowsForPage,
    showEpisodes: this.showEpisodes
  })

  hydrate = ({
    shows,
    showsForPage,
    recommendedShowsForPage,
    showEpisodes
  }: ReturnType<ShowStore['serialize']>): void => {
    this.shows = shows
    this.showsForPage.replace(showsForPage)
    this.recommendedShowsForPage.replace(recommendedShowsForPage)
    this.showEpisodes.replace(showEpisodes)
  }
}

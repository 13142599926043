/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import clsx from 'clsx'
import {Blocks} from './blocks'

import styles from './styles.module.css'

export const NewsList: React.FC = () => (
  <div className={styles.main}>
    <div className={styles.content}>
      <div className={styles.newsListWrapper}>
        <div
          className={clsx(
            styles.newsTitle,
            styles.short,
            styles.animated,
            styles.item
          )}
        />
        <div className={styles.newsList}>
          {new Array(4).fill('').map((_, index) => (
            <div key={index} className={styles.newsItem}>
              <div
                className={clsx(styles.newsImage, styles.animated, styles.item)}
              />
              <div className={styles.newsText}>
                <Blocks count={4} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

import {StateKeys} from 'common/constants'
import type {ApiRequestParams} from 'common/api/request'
import {CategoryStore} from './category'
import {ChannelStore} from './channel'
import {ShowStore} from './show'
import {NewsStore} from './news'
import {MetaTagsStore} from './meta-tags'
import {RouteParamsStore} from './route-params'
import {FavoriteStore} from './favorite'
import {PremiereStore} from './premiere'

export class Stores {
  readonly apiParams: Partial<ApiRequestParams>
  readonly categoryStore: CategoryStore
  readonly channelStore: ChannelStore
  readonly showStore: ShowStore
  readonly newsStore: NewsStore
  readonly metaTagsStore: MetaTagsStore
  readonly routeParamsStore: RouteParamsStore
  readonly favoriteStore: FavoriteStore
  readonly premiereStore: PremiereStore

  constructor(params: Partial<ApiRequestParams> = {}) {
    this.apiParams = params
    this.categoryStore = new CategoryStore(this)
    this.channelStore = new ChannelStore(this)
    this.showStore = new ShowStore(this)
    this.newsStore = new NewsStore(this)
    this.metaTagsStore = new MetaTagsStore(this)
    this.routeParamsStore = new RouteParamsStore(this)
    this.favoriteStore = new FavoriteStore(this)
    this.premiereStore = new PremiereStore(this)
  }

  public get storesWithStateKeys(): {stateKey: StateKeys; store: any}[] {
    return [
      {stateKey: StateKeys.CATEGORY_STORE, store: this.categoryStore},
      {stateKey: StateKeys.CHANNEL_STORE, store: this.channelStore},
      {stateKey: StateKeys.SHOW_STORE, store: this.showStore},
      {stateKey: StateKeys.NEWS_STORE, store: this.newsStore},
      {stateKey: StateKeys.META_TAGS_STORE, store: this.metaTagsStore},
      {stateKey: StateKeys.ROUTE_PARAMS_STORE, store: this.routeParamsStore},
      {stateKey: StateKeys.PREMIERE_STORE, store: this.premiereStore}
    ]
  }
}

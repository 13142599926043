import React, {type MouseEventHandler} from 'react'
import {
  NavLink as ReactRouterNavLink,
  type NavLinkProps as ReactRouterNavLinkProps
} from 'react-router-dom'
import {useSetShouldScrollToTop} from '../scroll-to-top'

interface NavLinkProps extends ReactRouterNavLinkProps {
  scroll?: boolean
  ref?: React.ForwardedRef<HTMLAnchorElement>
}

export const NavLink: React.FC<NavLinkProps> = React.forwardRef<
  HTMLAnchorElement,
  NavLinkProps
>(({children, scroll = true, onClick, ...props}, ref) => {
  const {setShouldScrollToTop} = useSetShouldScrollToTop()

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    setShouldScrollToTop(scroll)
    onClick?.(event)
  }

  return (
    <ReactRouterNavLink {...props} ref={ref} onClick={handleClick}>
      {children}
    </ReactRouterNavLink>
  )
})

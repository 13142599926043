import * as cookies from '@rambler-id/cookies'
import {isSSR} from '@rambler-id/react'
import {getState} from '@rambler-tech/react-toolkit/client'
import {StateKeys} from 'common/constants'
import {clearTrailingSlash} from 'common/utils/url'
import type {Runtime} from 'types/runtime'

const initRuntime = (runtime: Runtime): Runtime => {
  const {ruid} = cookies.getAll() ?? {}

  runtime.pathname = clearTrailingSlash(window.location.pathname)
  runtime.ruid = ruid
  runtime.userAgent = window.navigator.userAgent

  return runtime
}

let clientRuntime: Runtime

if (!isSSR) {
  const runtime = getState<Runtime>(StateKeys.RUNTIME)

  if (runtime) {
    clientRuntime = initRuntime(runtime)
  }
}

export {clientRuntime}

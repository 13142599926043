import {useEffect} from 'react'

interface AbortablePromise extends Promise<void> {
  abort(): void
}

const yieldToMain = () => {
  let timeout: number

  const promise = new Promise<void>((resolve) => {
    timeout = window.setTimeout(() => {
      resolve()
    }, 0)
  }) as AbortablePromise

  promise.abort = () => {
    window.clearTimeout(timeout)
  }

  return promise
}

export const useYieldToMain = (callback: () => void, dependencies: any[]) => {
  useEffect(() => {
    const promise = yieldToMain()

    const wait = async () => {
      await promise
      callback()
    }

    wait()

    return () => {
      promise.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

import {makeAutoObservable, observable} from 'mobx'
import type {Params} from 'react-router-dom'
import {isMatch, isValid} from 'date-fns'

import {TOMORROW_SLUG, Urls, YESTERDAY_SLUG} from 'common/constants/urls'
import {
  getDayDate,
  getTodayDate,
  getTomorrowDate,
  getYesterdayDate,
  isToday,
  isPast,
  isFuture
} from 'common/utils/date'
import {NOT_FOUND_ERROR} from 'common/constants/errors'

import type {Show} from 'types/show'
import type {Stores} from 'stores'
import type {ApiCity, City} from 'types/cities'
import type {AdSettings} from 'types/ad-settings'
import type {Runtime} from 'types/runtime'

import {AdService, CitiesService} from 'common/api'
import {
  getUrlForCategory,
  getUrlForChannel,
  getUrlForCity,
  getUrlForCityWithAbsoluteDate,
  getUrlForEpisode,
  getUrlForMainPage,
  getUrlForShow,
  getUrlForWeekPremieres,
  getUrlForWeekendPremieres
} from 'common/utils/url'
import {TV_API_ORIGIN} from 'common/utils/env'

export class RouteParamsStore {
  isFetching: boolean
  readonly stores: Stores
  readonly adService: AdService
  readonly citiesService: CitiesService
  pathname: string
  date: Date
  city: City
  category: string | null
  channel: string | null
  show: Pick<Show, 'titleTranslit' | 'titleId' | 'channelId'>
  routeMatch: string
  pageName: string
  adTransitionCounter: number
  adWidgetSettings: AdSettings
  runtime: Pick<Runtime, 'isGeoRu'>
  episode: string

  constructor(stores: Stores) {
    this.isFetching = true
    this.stores = stores
    this.adService = new AdService(stores.apiParams)
    this.citiesService = new CitiesService(stores.apiParams)

    this.pathname = ''
    this.date = new Date()
    this.city = {} as City
    this.category = null
    this.channel = null
    this.show = {} as Pick<Show, 'titleTranslit' | 'titleId' | 'channelId'>
    this.routeMatch = ''
    this.pageName = ''
    this.adTransitionCounter = 0
    this.adWidgetSettings = observable.object({
      isActive: false,
      isGeoRu: false,
      dateStart: null,
      dateEnd: null
    })
    this.runtime = observable.object({isGeoRu: false})
    this.episode = ''

    makeAutoObservable(this, {stores: false})
  }

  public updateRouteParams = async ({
    pathname,
    search,
    routeParams,
    routeMatch,
    geoId,
    isGeoRu
  }: {
    pathname: string
    search?: string
    routeParams?: Params
    routeMatch: string
    geoId: number
    isGeoRu: boolean
  }): Promise<void> => {
    this.pathname = pathname

    this.updateDateFromUrl({pathname, search, routeParams})
    this.updateRouteMatchFromUrl({routeMatch})
    this.updateCategoryFromUrl({routeParams})
    this.updateChannelFromUrl({routeParams})
    this.updateShowFromUrl({routeParams})
    this.updateEpisodeFromUrl({routeParams})

    this.runtime = {isGeoRu}

    await this.updateCityFromUrl({geoId, routeParams})
  }

  public updateDateFromUrl = ({
    pathname,
    search,
    routeParams
  }: {
    pathname: string
    search?: string
    routeParams?: Params
  }): void => {
    const dateFromUrl =
      new URLSearchParams(search).get('date') ??
      (routeParams && routeParams.date)

    if (dateFromUrl) {
      if (!isValid(new Date(dateFromUrl)) || !isMatch(dateFromUrl, 'y-MM-dd')) {
        throw new Error(NOT_FOUND_ERROR)
      }

      this.date = getDayDate(new Date(dateFromUrl))

      return
    }

    if (pathname.includes(YESTERDAY_SLUG)) {
      this.date = getYesterdayDate()

      return
    }

    if (pathname.includes(TOMORROW_SLUG)) {
      this.date = getTomorrowDate()

      return
    }

    this.date = getTodayDate()
  }

  private updateCityFromUrl = async ({
    geoId,
    routeParams
  }: {
    geoId: number
    routeParams?: Params
  }): Promise<void> => {
    const cityFetched =
      this.city.name &&
      (routeParams?.city
        ? this.city.nameTranslit === routeParams?.city
        : this.city.geoId === geoId)

    if (cityFetched) {
      return
    }

    const {items: cities} = await this.citiesService.listCities(
      routeParams?.city ? {city_trans: routeParams.city} : {geo_id: geoId}
    )

    if (!cities?.[0]) {
      throw new Error(NOT_FOUND_ERROR)
    }

    if (cities[0]) {
      this.city = this.formatCity({
        ...cities[0],
        /**
         * NOTE: Если в url присутствует слаг city города (routeParams?.city),
         * то нам не нужно присваивать в сторе geo_id,
         * чтобы при последующих переходах на url без city у нас сбрасывался город до дефолтного из куки
         */
        geo_id: routeParams?.city ? NaN : geoId
      })
    }
  }

  private updateCategoryFromUrl = ({
    routeParams
  }: {
    routeParams?: Params
  }): void => {
    this.category = routeParams?.category ?? null
  }

  private updateChannelFromUrl = ({
    routeParams
  }: {
    routeParams?: Params
  }): void => {
    if (routeParams?.channel) {
      this.channel = routeParams.channel
    }
  }

  private updateShowFromUrl = ({routeParams}: {routeParams?: Params}): void => {
    if (routeParams?.show) {
      const splitShowParams = routeParams.show.split('-')
      const titleTranslit = splitShowParams
        .slice(0, splitShowParams.length - 2)
        .join('-')
      const [channelId, titleId] = splitShowParams.reverse().map(Number)

      if (!titleTranslit || !channelId || !titleId) {
        throw new Error(NOT_FOUND_ERROR)
      }

      this.show = {
        channelId,
        titleId,
        titleTranslit
      }
    }
  }

  private updateEpisodeFromUrl = ({
    routeParams
  }: {
    routeParams?: Params
  }): void => {
    this.episode = routeParams?.['*']?.replace('/', '') ?? ''
  }

  private updateRouteMatchFromUrl = ({
    routeMatch
  }: {
    routeMatch: string
  }): void => {
    this.routeMatch = routeMatch
  }

  public updatePageName = ({pageName}: {pageName: string}): void => {
    if (this.pageName !== pageName) {
      this.incrementAdTransitionCounter()
    }

    this.pageName = pageName
  }

  private incrementAdTransitionCounter = (): void => {
    this.adTransitionCounter += 1
  }

  public fetchAdWidgetParams = async (): Promise<void> => {
    const {
      is_active: isActive,
      is_geo_ru: isGeoRu,
      date_start: dateStart,
      date_end: dateEnd
    } = await this.adService.getAdSettings()

    this.adWidgetSettings = {
      isActive,
      isGeoRu,
      dateStart: dateStart ? new Date(dateStart) : null,
      dateEnd: dateEnd ? new Date(dateEnd) : null
    }
  }

  public get routeParamsForPage(): Pick<
    RouteParamsStore,
    'pathname' | 'city' | 'date'
  > {
    return {
      pathname: this.pathname,
      city: this.city,
      date: this.date
    }
  }

  public get routeParamsForAd(): Pick<
    RouteParamsStore,
    'adTransitionCounter'
  > & {
    routeName: string
    isAdWidgetEnabled: boolean
  } {
    const {isActive, isGeoRu, dateStart, dateEnd} = this.adWidgetSettings

    return {
      adTransitionCounter: this.adTransitionCounter,
      routeName: this.pageName.split('::')[0],
      isAdWidgetEnabled:
        isActive &&
        (!isGeoRu || (isGeoRu && this.runtime.isGeoRu)) &&
        (dateStart && dateEnd ? isPast(dateStart) && isFuture(dateEnd) : true)
    }
  }

  public get canonicalUrl(): string {
    const routeName = this.pageName.split('::')[0]

    let path = ''

    switch (routeName) {
      case 'main': {
        if (this.pathname === Urls.MAIN) {
          return TV_API_ORIGIN
        }

        path = this.getUrlForMainPage()

        break
      }

      case 'personal': {
        path = Urls.PERSONAL
        break
      }

      case 'week': {
        path = getUrlForWeekPremieres({
          city: this.city
        })
        break
      }

      case 'weekend': {
        path = getUrlForWeekendPremieres({
          city: this.city
        })
        break
      }

      case 'channel': {
        if (this.channel) {
          path = getUrlForChannel({
            city: this.city,
            date: isToday(this.date) ? undefined : this.date,
            channelTranslit: this.channel
          })
        }

        break
      }

      case 'show': {
        if (this.channel) {
          path = getUrlForShow({
            city: this.city,
            channelTranslit: this.channel,
            channelId: this.show.channelId,
            titleId: this.show.titleId,
            titleTranslit: this.show.titleTranslit
          })
        }

        break
      }

      case 'episode': {
        if (this.channel) {
          path = getUrlForEpisode({
            city: this.city,
            channelTranslit: this.channel,
            channelId: this.show.channelId,
            titleId: this.show.titleId,
            titleTranslit: this.show.titleTranslit,
            episodeId: this.episode
          })
        }

        break
      }
    }

    return TV_API_ORIGIN + path
  }

  private getUrlForMainPage(): string {
    if (this.category) {
      return getUrlForCategory({
        city: this.city,
        /**
         * NOTE: don't pass date to getUrlForCategory
         * due to https://jira.rambler-co.ru/browse/TVPROG-189?focusedId=4844837&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-4844837
         */
        // date: isToday(this.date) ? undefined : this.date,
        categoryTranslit: this.category
      })
    }

    const isMoscowToday =
      this.city.nameTranslit === 'moskva' && this.pathname.includes('today')

    if (isMoscowToday) {
      return Urls.MOSKVA_TODAY
    }

    const isCityRouteWithoutDate =
      this.pathname.split('/').filter(Boolean).length === 1

    if (isCityRouteWithoutDate) {
      return getUrlForCity({
        city: this.city
      })
    }

    const isCityWithRelativeDate =
      this.pathname.split('/').filter(Boolean).length === 2 &&
      (this.pathname.includes('today') ||
        this.pathname.includes('tomorrow') ||
        this.pathname.includes('yesterday'))

    if (isCityWithRelativeDate) {
      return getUrlForMainPage({
        city: this.city,
        date: this.date
      })
    }

    return getUrlForCityWithAbsoluteDate({
      city: this.city,
      date: this.date
    })
  }

  private formatCity = ({
    geo_id: geoId,
    city: name,
    city_trans: nameTranslit,
    city_loct: nameInflected,
    time_zone: timeZone,
    utc
  }: ApiCity): City => ({
    geoId,
    name,
    nameTranslit,
    nameInflected,
    timeZone,
    utc
  })

  public serialize = (): Pick<
    RouteParamsStore,
    | 'pathname'
    | 'date'
    | 'city'
    | 'category'
    | 'channel'
    | 'routeMatch'
    | 'pageName'
    | 'adTransitionCounter'
    | 'adWidgetSettings'
    | 'runtime'
    | 'show'
    | 'episode'
  > => ({
    pathname: this.pathname,
    date: this.date,
    city: this.city,
    category: this.category,
    channel: this.channel,
    routeMatch: this.routeMatch,
    pageName: this.pageName,
    adTransitionCounter: this.adTransitionCounter,
    show: this.show,
    episode: this.episode,
    adWidgetSettings: this.adWidgetSettings,
    runtime: this.runtime
  })

  public hydrate = ({
    pathname,
    date,
    city,
    category,
    channel,
    routeMatch,
    pageName,
    adTransitionCounter,
    show,
    episode,
    adWidgetSettings,
    runtime
  }: ReturnType<RouteParamsStore['serialize']>): void => {
    this.pathname = pathname
    this.date = date
    this.city = city
    this.category = category
    this.channel = channel
    this.routeMatch = routeMatch
    this.pageName = pageName
    this.adTransitionCounter = adTransitionCounter
    this.show = show
    this.episode = episode
    this.adWidgetSettings = adWidgetSettings
    this.runtime = runtime
  }
}

import React from 'react'
import AdComponent from '@rambler-components/ad'

import clsx from 'clsx'
import {PUIDS_BY_PAGES} from 'common/constants/ad'

import styles from './styles.module.css'

interface AdProps {
  id: number
  className?: string
  style?: React.CSSProperties
  loadedClassName?: string
  loadedStyle?: React.CSSProperties
  preloadClassName?: string
  preloadHeight?: number
  options?: object
  adParams: {adTransitionCounter: number; routeName: string}
  isLazy?: boolean
  misc?: number
  forwardRef?: React.MutableRefObject<HTMLDivElement>
  onRender?: () => void
  children?: any
}

export const Ad: React.FC<AdProps> = ({
  id,
  className,
  style,
  loadedClassName,
  loadedStyle,
  preloadClassName,
  preloadHeight,
  options,
  adParams: {adTransitionCounter = 1, routeName = 'main'} = {},
  misc,
  isLazy,
  forwardRef,
  onRender,
  children
}) => {
  const puids = PUIDS_BY_PAGES[routeName] || {}

  return (
    <AdComponent
      id={id}
      idPrefix="lexjq"
      options={{...puids, ...options}}
      misc={misc}
      className={className}
      style={style}
      preloadClassName={clsx(styles.preloadClassName, preloadClassName)}
      loadedClassName={loadedClassName}
      loadedStyle={loadedStyle}
      preloadHeight={preloadHeight}
      reloadKey={adTransitionCounter}
      isLazy={isLazy}
      forwardRef={forwardRef}
      onRender={onRender}>
      {children}
    </AdComponent>
  )
}

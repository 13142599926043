/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import {useViewport} from '@rambler-id/react'
import clsx from 'clsx'

import {DESKTOP_BREAKPOINT} from 'common/constants/viewport'
import {Blocks} from './blocks'

import styles from './styles.module.css'

const DESKTOP_ROWS_COUNT = 20
const TABLET_ROWS_COUNT = 15

export const ChannelPage: React.FC = () => {
  const {isDesktop} = useViewport([DESKTOP_BREAKPOINT])

  return (
    <div>
      <div className={clsx(!isDesktop && styles.tablet)}>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.leftColumn}>
              <div className={styles.row}>
                <div
                  className={clsx(
                    styles.pageTitle,
                    styles.channelPage,
                    styles.animated,
                    styles.item
                  )}
                />
                <div
                  className={clsx(
                    styles.select,
                    styles.animated,
                    styles.channelPage,
                    styles.item
                  )}
                />
              </div>
              <div className={styles.channelInfo}>
                <Blocks count={3} />
              </div>
              <div className={clsx(styles.navigation, styles.animated)} />
              <div className={styles.schedule}>
                {new Array(isDesktop ? DESKTOP_ROWS_COUNT : TABLET_ROWS_COUNT)
                  .fill(0)
                  .map((_, index) => (
                    <div key={index} className={styles.scheduleRow}>
                      <div
                        className={clsx(
                          styles.time,
                          styles.animated,
                          styles.item
                        )}
                      />
                      <div
                        className={clsx(
                          styles.showTitle,
                          styles.animated,
                          styles.item
                        )}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div
                className={clsx(styles.news, styles.animated, styles.noMargin)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

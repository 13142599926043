import React, {createContext, useContext, useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'

interface ContextData {
  setShouldScrollToTop: (value: boolean) => void
}

const initialData: ContextData = {
  setShouldScrollToTop: () => undefined
}

const ScrollToTopContext = createContext<ContextData>(initialData)

export const ScrollToTopContextProvider: React.FC<{
  children: React.ReactNode
}> = ({children}) => {
  const {pathname} = useLocation()

  const shouldScrollToTop = useRef(true)

  useEffect(() => {
    if (shouldScrollToTop.current) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  const setShouldScrollToTop = (value: boolean): void => {
    shouldScrollToTop.current = value
  }

  return (
    <>
      <ScrollToTopContext.Provider value={{setShouldScrollToTop}}>
        {children}
      </ScrollToTopContext.Provider>
    </>
  )
}

export const useSetShouldScrollToTop = (): ContextData =>
  useContext(ScrollToTopContext)
